import styled from "styled-components";
import { colors, mediaQueryMax } from "../../assets/styles/vars";

export const NearestCentrosWrapper = styled.div`
	.title {
		padding: 25px;
		border-bottom: 1px solid ${colors.black0A};

		h5 {
			color: ${colors.white};
			margin-bottom: 5px;
		}

		p {
			color: #6b6b6b;
		}
	}

	.stationList {
		overflow-x: hidden;
		overflow-y: scroll;
		height: 383px;
	}
`;
