import styled, { css } from "styled-components";
import { colors } from "../../../assets/styles/vars";

export const PremiumBlockWrapper = styled.div`
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	box-sizing: border-box;
	gap: 24px;
	div {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		.icon {
			svg {
				path {
					fill: ${colors.white};
				}
			}
		}
		h5 {
			margin: 0;
			color: ${colors.white};
		}
	}
	${(props) =>
		props.layout === "horz"
			? css`
					background-color: ${colors.black16};
					justify-content: space-between;
					padding: 10px 25px;
					div {
						align-items: center;
						.icon {
							width: 28px;
						}
					}
			  `
			: css`
					background-color: rgba(0, 0, 0, 0.9);
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					padding: 15px;
					justify-content: center;
					flex-direction: column;
					div {
						justify-content: center;
						flex-direction: column;
						.icon {
							width: 50px;
						}
					}
			  `};
`;
