import styled from "styled-components";
import { colors, mediaQueryMax } from "../../assets/styles/vars";

export const DayBlockWrapper = styled.div`
	min-width: 150px;
	outline: 0;
	padding: 30px 0 0;
	box-sizing: border-box;
	position: relative;
	background: ${colors.black10};
	//@media (min-width: 830px) {
	@media ${mediaQueryMax.lg} {
		min-width: 182px;
	}

	&:after {
		content: "";
		display: block;
		position: absolute;
		right: 0;
		top: 30px;
		width: 1px;
		height: 595px;
		background-color: ${colors.black00};
	}

	// CSS TRANSITION FOR HoursQtySelector
	.fade-enter {
		opacity: 0;
	}
	.fade-enter-active {
		opacity: 1;
		transition: all 300ms ease-in;
	}
	.fade-exit {
		opacity: 1;
	}
	.fade-exit-active {
		opacity: 0;
		transition: all 300ms ease-out;
	}
`;

export const HoursQtySelector = styled.div`
	.buttonGroup {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 15px;
	}
	button {
		color: ${colors.main};
		&.active {
			text-decoration: underline;
		}
		span.icon {
			width: 20px;
			svg {
				path {
					fill: ${colors.main};
				}
			}
		}
	}
`;

export const DayBlockHeader = styled.div`
	margin-bottom: 16px;
	height: 20px;
	overflow: hidden;
	.titleWrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		//justify-content: space-between;
		overflow: hidden;
		gap: 25px;
		padding: 0 20px;
		//@media (min-width: 830px) {
		@media ${mediaQueryMax.lg} {
			padding: 0 34px;
		}
		h4 {
			text-align: left;
			font-size: 16px;
			line-height: 20px;
			text-transform: uppercase;
			color: ${colors.gray40};
			margin: 0;
		}
	}
`;
