import React from "react";
import ContentLoader from "react-content-loader";
import { colors } from "../../../assets/styles/vars";

const ActionSkeleton = (props) => (
	<ContentLoader
		speed={2}
		width={26}
		height={26}
		viewBox="0 0 26 26"
		backgroundColor={colors.gray70}
		foregroundColor={colors.black13}
		{...props}
	>
		<circle cx="13" cy="13" r="13" />
	</ContentLoader>
);

export default ActionSkeleton;
