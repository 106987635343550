import styled, { css } from "styled-components";

export const ForecastTempsListWrapper = styled.div`
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		gap: 15px;
		${(props) =>
			props.layout === "horz"
				? css`
						align-items: center;
				  `
				: css`
						flex-direction: column;
						justify-content: center;
				  `};
		li {
			cursor: pointer;
			margin: 0;
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #a6a6a6;
			font-size: 18px;
			line-height: 21px;
			transition: 0.3s all ease-in-out;

			&:hover,
			&.current {
				font-weight: 600;
				color: #e1e1e1;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
`;
