import React, { useRef, useState, useEffect } from "react";
import { BlockInfoRow, DayBlockBars, DayBlockContent } from "./styled";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import {
	getCielo,
	getMuchasRafagas,
	getMuchoViento,
	getTemperaturaClass,
	getVisibilidadString,
	transpolateNieveToPx,
} from "../../utils/forecast";
import { ReactSVG } from "react-svg";
import ReactTooltip from "react-tooltip";
import CotasCanvas from "../ExtendedForecastDay/CotasCanvas";
import { TransitionGroup } from "semantic-ui-react";
import { CSSTransition } from "react-transition-group";

const ExtendedForecastDayContent = (props) => {
	const { franjas, cotas, units, hoursQty } = props;
	const intl = useIntl();

	const [contentWidth, setContentWidth] = useState(0);
	const contentWidthRef = useRef();

	useEffect(() => {
		const contentWidthTemp = contentWidthRef?.current?.offsetWidth;
		// console.log("contentWidthTemp", contentWidthTemp);
		setContentWidth(contentWidthTemp);
		// }, [hoursQty]);
	}, [franjas, hoursQty]);

	return (
		<DayBlockContent width={contentWidth}>
			{/* ICONOS */}
			<BlockInfoRow
				className="iconosRow"
				hoursQty={hoursQty}
				ref={contentWidthRef}
			>
				<TransitionGroup>
					{franjas?.map((franja, index) => {
						const franjaIndex = franja[0];
						const franjaValue = franja[1];

						const getMsg = () => {
							if (franjaIndex === "am") {
								return <FormattedMessage id={`clima.dia`} />;
							} else if (franjaIndex === "pm") {
								return <FormattedMessage id={`clima.tarde`} />;
							} else if (franjaIndex === "noche") {
								return <FormattedMessage id={`clima.noche`} />;
							} else {
								return franjaIndex;
							}
						};

						const icono =
							franjaIndex === "noche"
								? `/images/clima_iconos/${franjaValue.clima_codigo}_night.svg`
								: `/images/clima_iconos/${franjaValue.clima_codigo}.svg`;
						const tooltip = getCielo(franjaValue.clima_codigo);

						return (
							<CSSTransition
								key={index}
								nodeRef={franjaValue.nodeRef}
								timeout={500}
								className="item icon-item"
							>
								<div className="iconWrapper" ref={franjaValue.nodeRef}>
									<ReactSVG
										data-tip=""
										data-for={`icon-${index}`}
										src={icono}
										className="icon"
										wrapper="span"
									/>
									<p>{getMsg()}</p>

									<ReactTooltip
										id={`icon-${index}`}
										place="bottom"
										type="light"
										effect="solid"
										className="tooltip"
									>
										<span>{tooltip}</span>
									</ReactTooltip>
								</div>
							</CSSTransition>
						);
					})}
				</TransitionGroup>
			</BlockInfoRow>

			{/* DAY BARS */}
			<DayBlockBars>
				<div className="dayBarsItems">
					<div className="dayCanvasWrapper">
						<CotasCanvas height={125} cotas={cotas} />
					</div>
					<div className="dayBarsLinesWrapper">
						{[...Array(5).keys()].map((number) => (
							<div className="dayBarsLine" key={number} />
						))}
					</div>
					<div className="dayBars">
						<TransitionGroup>
							{franjas?.map((franja, index) => {
								// const franjaIndex = franja[0];
								const franjaValue = franja[1];

								const nieveCm = franjaValue.nieve_cm;
								const nieveIn = franjaValue.nieve_in;
								const nieveHeight =
									transpolateNieveToPx(nieveCm).toString() + "px";
								return (
									<CSSTransition
										key={index}
										nodeRef={franjaValue.nodeRef}
										timeout={500}
										className="item"
									>
										<div ref={franjaValue.nodeRef}>
											<div
												key={index}
												className="dayBar"
												style={{
													height: nieveHeight,
												}}
												data-tip=""
												data-for={`bar-${index}`}
											>
												<ReactTooltip
													id={`bar-${index}`}
													place="bottom"
													type="light"
													effect="solid"
													className="tooltip"
												>
													<span>
														{units === "m" ? nieveCm : nieveIn}{" "}
														{units === "m" ? (
															<FormattedMessage id="clima.cm_nieve_tooltip" />
														) : (
															<FormattedMessage id="clima.in_nieve_tooltip" />
														)}
													</span>
												</ReactTooltip>
											</div>
										</div>
									</CSSTransition>
								);
							})}
						</TransitionGroup>
					</div>
				</div>
			</DayBlockBars>

			{/* NIEVE */}
			<BlockInfoRow>
				<TransitionGroup>
					{franjas?.map((franja, index) => {
						// const franjaIndex = franja[0];
						const franjaValue = franja[1];
						return (
							<CSSTransition
								key={index}
								nodeRef={franjaValue.nodeRef}
								timeout={500}
								className="item nieve-item"
							>
								<p ref={franjaValue.nodeRef}>
									{units === "m"
										? franjaValue.nieve_cm
										: franjaValue.nieve_in}
								</p>
							</CSSTransition>
						);
					})}
				</TransitionGroup>
			</BlockInfoRow>

			{/* PRECIPITACIONES */}
			<BlockInfoRow>
				<TransitionGroup>
					{franjas?.map((franja, index) => {
						// const franjaIndex = franja[0];
						const franjaValue = franja[1];
						return (
							<CSSTransition
								key={index}
								nodeRef={franjaValue.nodeRef}
								timeout={500}
								className="item precipitaciones-item"
							>
								<p ref={franjaValue.nodeRef}>
									{units === "m"
										? franjaValue.precipitaciones_mm
										: franjaValue.precipitaciones_in}
								</p>
							</CSSTransition>
						);
					})}
				</TransitionGroup>
			</BlockInfoRow>

			{/* COTA */}
			<BlockInfoRow>
				<TransitionGroup>
					{franjas?.map((franja, index) => {
						// const franjaIndex = franja[0];
						const franjaValue = franja[1];
						return (
							<CSSTransition
								key={index}
								nodeRef={franjaValue.nodeRef}
								timeout={500}
								className="item cota-item"
							>
								<p ref={franjaValue.nodeRef}>
									{units === "m"
										? franjaValue.cota_m
										: franjaValue.cota_f}
								</p>
							</CSSTransition>
						);
					})}
				</TransitionGroup>
			</BlockInfoRow>

			{/* VISIBILIDAD */}
			<BlockInfoRow>
				<TransitionGroup>
					{franjas?.map((franja, index) => {
						// const franjaIndex = franja[0];
						const franjaValue = franja[1];

						const visibilidad = getVisibilidadString(
							franjaValue.visibilidad_km,
							intl,
						);
						const is_vis_bad =
							visibilidad === "Mala" || visibilidad === "Bad";
						return (
							<CSSTransition
								key={index}
								nodeRef={franjaValue.nodeRef}
								timeout={500}
								className={`item visibilidad-item ${
									is_vis_bad ? "bad" : ""
								}`}
							>
								<p ref={franjaValue.nodeRef}>{visibilidad}</p>
							</CSSTransition>
						);
					})}
				</TransitionGroup>
			</BlockInfoRow>

			{/* VIENTO */}
			<BlockInfoRow>
				<TransitionGroup>
					{franjas?.map((franja, index) => {
						// const franjaIndex = franja[0];
						const franjaValue = franja[1];
						return (
							<CSSTransition
								key={index}
								nodeRef={franjaValue.nodeRef}
								timeout={500}
								className={`item viento-item ${getMuchoViento(
									franjaValue.viento_kph,
								)}`}
							>
								<p ref={franjaValue.nodeRef}>
									{units === "m"
										? franjaValue.viento_kph
										: franjaValue.viento_mph}
								</p>
							</CSSTransition>
						);
					})}
				</TransitionGroup>
			</BlockInfoRow>

			{/* RAFAGAS */}
			<BlockInfoRow>
				<TransitionGroup>
					{franjas?.map((franja, index) => {
						// const franjaIndex = franja[0];
						const franjaValue = franja[1];
						return (
							<CSSTransition
								key={index}
								nodeRef={franjaValue.nodeRef}
								timeout={500}
								className={`item rafagas-item ${getMuchasRafagas(
									franjaValue.rafagas_kph,
								)}`}
							>
								<p ref={franjaValue.nodeRef}>
									{units === "m"
										? franjaValue.rafagas_kph
										: franjaValue.rafagas_mph}
								</p>
							</CSSTransition>
						);
					})}
				</TransitionGroup>
			</BlockInfoRow>

			{/* DIRECCION VIENTO */}
			<BlockInfoRow>
				<TransitionGroup>
					{franjas?.map((franja, index) => {
						// const franjaIndex = franja[0];
						const franjaValue = franja[1];
						return (
							<CSSTransition
								key={index}
								nodeRef={franjaValue.nodeRef}
								timeout={500}
								className="item direccion-item"
							>
								<div ref={franjaValue.nodeRef}>
									<div
										className={`infoArrow ${franjaValue.viento_direccion}`}
										key={index}
									>
										<div className="infoArrowLine" />
										<div className="infoArrowTriangle" />
									</div>
								</div>
							</CSSTransition>
						);
					})}
				</TransitionGroup>
			</BlockInfoRow>

			{/*EMPTY SPACE*/}
			<BlockInfoRow>
				<p />
			</BlockInfoRow>

			{/* TEMP MAX */}
			<BlockInfoRow>
				<TransitionGroup>
					{franjas?.map((franja, index) => {
						// const franjaIndex = franja[0];
						const franjaValue = franja[1];
						return (
							<CSSTransition
								key={index}
								nodeRef={franjaValue.nodeRef}
								timeout={500}
								className={`item temp-max-item ${getTemperaturaClass(
									franjaValue.temperatura_maxima_c,
								)}`}
							>
								<p ref={franjaValue.nodeRef}>
									{units === "m"
										? franjaValue.temperatura_maxima_c
										: franjaValue.temperatura_maxima_f}
								</p>
							</CSSTransition>
						);
					})}
				</TransitionGroup>
			</BlockInfoRow>

			{/* TEMP MIN */}
			<BlockInfoRow>
				<TransitionGroup>
					{franjas?.map((franja, index) => {
						// const franjaIndex = franja[0];
						const franjaValue = franja[1];
						return (
							<CSSTransition
								key={index}
								nodeRef={franjaValue.nodeRef}
								timeout={500}
								className={`item temp-min-item ${getTemperaturaClass(
									franjaValue.temperatura_minima_c,
								)}`}
							>
								<p ref={franjaValue.nodeRef}>
									{units === "m"
										? franjaValue.temperatura_minima_c
										: franjaValue.temperatura_minima_f}
								</p>
							</CSSTransition>
						);
					})}
				</TransitionGroup>
			</BlockInfoRow>

			{/* SENSACION TERMICA */}
			<BlockInfoRow>
				<TransitionGroup>
					{franjas?.map((franja, index) => {
						// const franjaIndex = franja[0];
						const franjaValue = franja[1];
						return (
							<CSSTransition
								key={index}
								nodeRef={franjaValue.nodeRef}
								timeout={500}
								className={`item termica-item ${getTemperaturaClass(
									franjaValue.sensacion_termica_c,
								)}`}
							>
								<p ref={franjaValue.nodeRef}>
									{units === "m"
										? franjaValue.sensacion_termica_c
										: franjaValue.sensacion_termica_f}
								</p>
							</CSSTransition>
						);
					})}
				</TransitionGroup>
			</BlockInfoRow>
		</DayBlockContent>
	);
};

export default ExtendedForecastDayContent;
