import styled from "styled-components";
import { SlideDown } from "react-slidedown";
import { colors, mediaQueryMax } from "../../assets/styles/vars";
import {
	BlockInfoRow,
	DayBlockBars,
	DayBlockContent,
} from "../ExtendedForecastDayContent/styled";

export const DayBlockMobileWrapper = styled.div`
	background-color: ${colors.black16};
	display: flex;
	flex-direction: column;
	align-items: center;
	border-bottom: solid 5px #0b0b0b;
	position: relative;
	transition: 0.6s;

	&.active {
		background-color: #111111;
	}

	.fade-enter {
		opacity: 0;
	}
	.fade-enter-active {
		opacity: 1;
		transition: opacity 300ms ease-in-out;
	}
	.fade-exit {
		opacity: 1;
	}
	.fade-exit-active {
		opacity: 0;
		transition: opacity 300ms ease-in-out;
	}
`;

export const DayBlockMobileHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	box-sizing: border-box;
	padding: 18px 25px;
	gap: 10vw;
	@media ${mediaQueryMax.xs2} {
		//padding: 15px;
		gap: 6vw;
	}
	.headerGroup {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10vw;
		flex: 1;
		@media ${mediaQueryMax.xs2} {
			gap: 6vw;
		}
	}
	.headerItem {
		color: ${colors.white};
		&.snow {
			flex: 0 0 42px;
		}
		&.tempMinMax {
			flex: 0 0 45px;
		}
		p {
			line-height: 20px;
			span {
				font: inherit;
				color: ${colors.main};
			}
		}
		span {
			&.icon {
				width: 26px;
			}
		}
		&.arrow {
			span {
				&.icon {
					width: 8px;
					span {
						transition: transform 0.6s;
						transform: rotate(0deg);
					}
				}
			}
			&.down {
				span {
					&.icon {
						span {
							transform: rotate(90deg);
						}
					}
				}
			}
		}
	}
`;

export const DayBlockMobileInfoSlideDown = styled(SlideDown)`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	${DayBlockContent} {
		background-color: ${colors.black16};
		padding-top: 14px;
		//flex: 1;
		width: auto !important;
		min-width: calc(100% - 80px);
		max-width: calc(100% - 80px);
		overflow-x: auto;
		overflow-y: hidden;
		${BlockInfoRow} {
			justify-content: space-evenly;
			&.iconosRow {
				align-self: auto;
				gap: 10px;
			}
		}
		${DayBlockBars} {
			.dayBarsItems {
				.dayBars {
					justify-content: space-evenly;
				}
			}
		}
	}
`;
