import styled, { css } from "styled-components";
import { StyledTooltip } from "../../../assets/styles/pageCommon";
import { colors } from "../../../assets/styles/vars";
import {
	CustomButton,
	StyledSliderButton,
} from "../../../assets/styles/globalCommon";

export const ActionButtonWrapper = styled.div`
	${CustomButton},
	${StyledSliderButton} {
		position: relative;
		cursor: pointer;
		${(props) =>
			props.withText
				? css`
						.icon-filled-wrapper {
							width: 50px;
							height: 20px;
							span.icon {
								path {
									fill: ${colors.black00};
								}
							}
						}
				  `
				: css`
						width: 26px;
						height: 26px;
						> span.icon {
							width: 100%;
							height: 100%;
						}
						.icon-filled-wrapper {
							width: 100%;
							height: 100%;
							span.icon {
								path {
									fill: ${colors.main};
								}
							}
						}
				  `};
		> span.icon {
			path {
				fill: ${colors.gray70};
				transition: fill 0.3s ease-in-out;
			}
			&.active,
			&:hover {
				path {
					fill: ${colors.main};
				}
			}
		}
		.icon-filled-wrapper {
			position: relative;
			display: block;
			span.icon {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				transition: opacity 0.3s ease-in-out;
				path {
				}
				&.normal {
					z-index: 0;
					opacity: 1;
				}
				&.hover {
					z-index: 2;
					opacity: 0;
				}
			}
			&.active,
			&:hover {
				span.icon {
					&.normal {
						opacity: 0;
					}
					&.hover {
						opacity: 1;
					}
				}
			}
		}
	}
	.tooltipWrapper {
		pointer-events: auto !important;
		position: absolute;
		top: 42%;
		left: 50%;
		transform: translate(-50%, -50%);
		.circle {
			width: 70px;
			height: 70px;
			overflow: hidden;
			border-radius: 50%;
			border: 2px solid ${colors.main};
		}
		${StyledTooltip} {
			width: 154px;
			text-align: left;
			padding: 15px;
			&.show {
				opacity: 1;
			}
		}
	}
`;
