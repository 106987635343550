import React from "react";
import { ReactSVG } from "react-svg";
import { CustomLink } from "../../../assets/styles/globalCommon";
import { PremiumBlockWrapper } from "./styled";

const PremiumBlock = ({ layout }) => {
	return (
		<PremiumBlockWrapper layout={layout}>
			<div>
				<ReactSVG
					src="/img/icons/icon-lock.svg"
					wrapper="span"
					className="icon"
				/>
				<h5>14 dias</h5>
			</div>
			<CustomLink to="/subscriptions">
				<p>Ir a premium</p>
			</CustomLink>
		</PremiumBlockWrapper>
	);
};

export default PremiumBlock;
