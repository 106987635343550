import styled from "styled-components";
import { PageContent, PageContentWrapper, StyledTooltip } from "../pageCommon";
import { colors, mediaQueryMax } from "../vars";
import { InfoCirclesWrapper } from "../../../components/Common/InfoCircles/styled";
import { StarsWrapper } from "../../../components/Stars/styled";

export const ResortSection = styled.section`
	background-color: ${colors.black0A};
	${PageContentWrapper} {
		${PageContent} {
			@media ${mediaQueryMax.lg2} {
				padding-left: 35px;
				padding-right: 35px;
			}
			@media ${mediaQueryMax.md} {
				padding-left: 0;
				padding-right: 0;
			}
		}

		// RESORT HEADER
		&.resortHeader {
			${PageContent} {
				min-height: 290px;
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				justify-content: space-between;
			}
			.headerLinks {
				width: 100%;
				padding: 36px 0;
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				justify-content: space-between;

				.headerAdd {
					margin-right: 10px;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					gap: 20px;

					button {
					}
				}
			}
			.headerContent {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: flex-end;
				.left,
				.right {
					//flex: 1;
				}
				.left {
					flex: 0.65;
					.starsWrapper {
						width: 140px;
						position: relative;
						margin-bottom: 10px;
						cursor: pointer;
						.tooltipRef {
							position: absolute;
							left: 0;
							top: 0;
							width: 100%;
							height: 100%;
						}
						${StarsWrapper} {
						}
						${StyledTooltip} {
						}
					}
					h2 {
						margin-bottom: 0;
					}
					p {
						font-weight: 300;
						font-size: 22px;
						color: ${colors.black00};
					}
				}
				.right {
					flex: 0.35;
					${InfoCirclesWrapper} {
						justify-content: flex-end;
					}
				}
			}
			@media ${mediaQueryMax.lg2} {
				${PageContent} {
					//height: 320px;
				}
			}
			@media ${mediaQueryMax.md} {
				${PageContent} {
					min-height: 150px;
				}
				padding-bottom: 15px;
				${PageContent} {
					justify-content: flex-end;
				}
				.headerLinks {
					padding: 0;

					> a,
					> span {
						display: none;
					}

					.headerAdd {
						margin-right: 0;
						position: fixed;
						width: 100%;
						left: 0;
						bottom: 0;
						z-index: 1002;
						background-color: ${colors.white};
						padding: 15px 25px 20px;
						box-sizing: border-box;
						justify-content: space-evenly;

						button {
							flex-direction: column;
							justify-content: center;
							gap: 8px;

							p {
								order: 1;
								font-size: 15px;
							}

							span.icon {
								order: 0;
							}
						}
					}
				}
				.headerContent {
					width: 100%;
					box-sizing: border-box;
					padding: 0 25px;
					position: relative;

					.left {
						width: 100%;
						margin: 0;
						h2 {
							font-size: 25px;
							line-height: 32px;
						}

						p {
							font-size: 15px;
							line-height: 20px;
							font-weight: normal;
						}
					}

					.right {
						display: none;
					}
				}
			}
			@media ${mediaQueryMax.sm} {
				.headerLinks {
					.headerAdd {
						justify-content: center;

						button {
							p {
								font-size: 15px;
							}
						}
					}
				}
			}
			@media ${mediaQueryMax.xs2} {
				.headerLinks {
					.headerAdd {
						gap: 16px;

						padding: 15px 10px 20px;

						button {
							p {
								font-size: 13px;
							}
						}
					}
				}
			}
		}

		// RESORT HEADER

		// RESORT MAIN INFO
		&.resortMainInfo {
			margin-top: -30px;
			padding-bottom: 20px;
			.mainInfoContent {
				width: 100%;
				overflow: hidden;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.infoLastSnowWrapper {
					flex: 0 0 73.5%;
					max-width: 73.5%;

					&.fullWidth {
						flex: 0 0 100%;
						max-width: 100%;
					}
				}
				.infoLastSnow {
					min-height: 78px;
					background: ${colors.white};
					padding: 15px 18px 15px 30px;
					box-sizing: border-box;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					align-items: center;

					.info01 {
						display: flex;
						align-items: center;
						width: 10%;

						svg {
							display: flex;
							margin-right: 10px;
						}

						p {
							font-weight: 500;
							font-size: 30px;
							line-height: 34px;
							color: ${colors.black00};

							span {
								display: none;
							}
						}
					}

					.info02 {
						ul {
							display: flex;

							li {
								text-align: center;
								margin-right: 24px;

								&:last-child {
									margin: 0;
								}

								p {
									color: ${colors.gray4C};
									font-size: 15px;
									line-height: 17px;
									margin-bottom: 2px;

									span {
										display: none;
									}

									&.number {
										color: ${colors.black00};
										font-size: 18px;
										line-height: 21px;
									}
								}
							}
						}
					}

					.info03 {
						p {
							font-size: 15px;
							line-height: 17px;
							color: ${colors.black00};
						}
					}

					.info04 {
						a,
						button {
							background-color: ${colors.whiteF4};
						}

						.add {
							display: none;
						}
					}
				}
				.infoLastSnow02 {
					box-sizing: border-box;
					display: flex;
					flex-wrap: wrap;
					gap: 30px;
					align-items: flex-end;
					padding: 30px 30px 20px;
					justify-content: space-between;

					.left {
						flex: 1;
						max-width: 360px;
						display: flex;
						align-items: center;
						h3 {
							line-height: 1.2;
							margin-bottom: 0;
							color: ${colors.white};
						}
					}

					.right {
						.desktopInfoGrid {
							h5 {
								color: ${colors.white};
								display: flex;
								flex-wrap: wrap;
								align-items: center;
								gap: 8px;
								span {
									width: 18px;
									svg {
										path {
											fill: ${colors.white};
										}
									}
								}
							}

							ul {
								display: flex;
								flex-wrap: wrap;
								gap: 25px;
								align-items: flex-end;

								margin-top: 12px;

								li {
									width: 90px;
									.gradienWrapper {
										width: 100%;
										height: 125px;
										position: relative;
										overflow: hidden;
										margin-bottom: 5px;
										.gradientBg {
											position: absolute;
											left: 0;
											bottom: 0;
											height: 100%;
											width: 100%;
											z-index: 0;
											background: linear-gradient(
												180deg,
												${colors.black0A} 0%,
												#202020 100%
											);
										}
										.gradientNieve {
											position: absolute;
											left: 0;
											bottom: 0;
											width: 100%;
											z-index: 1;
											background: linear-gradient(
												180deg,
												rgba(0, 255, 252, 0.3) 0%,
												rgba(0, 255, 252, 0) 100%
											);
											display: flex;
											flex-wrap: wrap;
											padding: 4px 8px;
											box-sizing: border-box;
											align-items: flex-start;
											justify-content: center;
											p {
												color: ${colors.main};
												margin: 0;
												font-size: 18px;
												font-weight: 400;
											}
										}
									}
									small {
										color: #787878;
										text-transform: uppercase;
										font-size: 16px;
										line-height: 18px;
										text-align: center;
										display: block;
									}
								}
							}
						}
					}
				}
			}
			@media ${mediaQueryMax.xl2} {
				.mainInfoContent {
					.infoLastSnowWrapper {
						flex: 0 0 70%;
						max-width: 70%;
					}
					.infoLastSnow {
						width: 100%;
					}
					.infoLastSnow02 {
						padding: 20px;
					}
					.adsWrapper {
						display: none;
					}
				}
			}
			@media ${mediaQueryMax.lg2} {
				.mainInfoContent {
					.infoLastSnow02 {
						width: 100%;

						.left {
							width: 100%;
							align-items: center;

							h3 {
								font-size: 26px;
							}
						}
					}
				}
			}
			@media ${mediaQueryMax.lg} {
				.mainInfoContent {
					.infoLastSnowWrapper {
						flex: 0 0 100%;
						max-width: 100%;
					}
				}
			}
			@media (max-width: 935px) {
				.mainInfoContent {
					.infoLastSnow {
						.info02 {
							ul {
								li {
									p {
										span {
											display: inline-block;
											margin-right: 3px;
										}

										&:not(.number) {
											display: none;
										}
									}
								}
							}
						}

						.info03,
						.info04 {
							display: none;
						}
					}
				}
			}
			@media ${mediaQueryMax.md} {
				margin-top: 0;
				padding-bottom: 0;
				.mainInfoContent {
					margin: 0;
					width: 100%;
					.infoLastSnow {
						flex-wrap: wrap;
						background: none;
						padding: 0;
						align-items: unset;

						.info01,
						.info02,
						.info03,
						.info04 {
							display: flex;
							align-items: center;
						}

						.info01,
						.info02 {
							padding: 15px 0;
						}

						.info03,
						.info04 {
							padding: 30px 0;
						}

						.info01 {
							background: ${colors.white};
							width: 30%;

							p {
								font-size: 25px;
								line-height: 32px;
							}

							svg {
								width: 21px;
								margin-left: 25px;
							}
						}

						.info02 {
							background: ${colors.white};
							width: 70%;
							justify-content: flex-end;

							ul {
								justify-content: flex-end;
								margin-right: 25px;

								li {
									margin-right: 10px;

									p {
										&.number {
											margin: 0;
											font-size: 14px;
											line-height: 16px;
										}
									}
								}
							}
						}

						.info03 {
							width: 60%;

							p {
								color: ${colors.white};
								font-size: 13px;
								line-height: 20px;
								margin-left: 25px;
							}
						}

						.info04 {
							width: 40%;
							justify-content: flex-end;

							button {
								display: none;
							}

							.add {
								display: flex;
								justify-content: flex-end;
								gap: 24px;
								margin-right: 25px;

								a,
								button {
									display: flex;
									background: none;
									padding: 0;
								}
							}
						}
					}
					.infoLastSnow02 {
						flex-wrap: wrap;
						padding: 0;

						.left,
						.right {
							padding: 0 25px 35px;
							width: 100%;
							height: auto;
							align-items: unset;
						}

						.left {
						}

						.right {
							background: ${colors.black16};
							padding-top: 20px;

							.desktopInfoGrid {
								width: 100%;

								p {
									font-size: 17px;
									line-height: 21px;
									margin-bottom: 15px;
								}

								ul {
									display: flex;
									width: 100%;
									height: auto;

									li {
										flex: 1 1;
										margin: 0;
										p {
											background: none;
											width: auto;
											height: 100% !important;
											border-bottom: 1px solid #333333;
											margin-bottom: 0;

											span {
												font-size: 15px;
												line-height: 17px;
												display: flex;
												justify-content: flex-end;
												padding-right: 10px;

												> div {
													display: flex;
													margin-right: 7px;

													svg {
														width: 13px;
													}
												}
											}
										}

										small {
											display: flex;
											justify-content: flex-end;
											position: relative;
											font-weight: 300;
											font-size: 13px;
											line-height: 15px;
											padding-top: 6px;
											padding-right: 10px;
											text-transform: lowercase;

											&:after {
												content: "";
												position: absolute;
												top: 0;
												right: 0;
												height: 21px;
												width: 1px;
												background-color: #333333;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		// RESORT MAIN INFO

		// RESORT FORECAST
		&.resortForecast {
			overflow: visible;
			padding-bottom: 0;
			${PageContent} {
				padding: 0;
			}
		}
		// RESORT FORECAST

		// RESORT EXTRA BLOCKS
		&.resortExtraBlock {
			@media ${mediaQueryMax.md} {
				padding-bottom: 0;
			}
		}
		// RESORT EXTRA BLOCKS
	}
`;

export const ResortExtraInfoSection = styled.div`
	${PageContentWrapper} {
		${PageContent} {
			@media ${mediaQueryMax.lg2} {
				padding-left: 35px;
				padding-right: 35px;
			}
			@media ${mediaQueryMax.md} {
				padding-left: 25px;
				padding-right: 25px;
			}
		}
		// RESORT INFO EXTRA
		&.resortInfoExtra {
			.imgsWrapper {
				margin: 30px auto;

				ul {
					display: flex;
					justify-content: space-between;

					li {
						width: 600px;
						margin-right: 80px;

						&:last-child {
							margin: 0;
						}

						> h3 {
							padding-bottom: 10px;
						}

						> p {
							line-height: 25px;
							color: #6b6b6b;
							padding-bottom: 40px;
						}

						> a {
							display: flex;
							height: 300px;
							margin-bottom: 20px;

							img {
								object-fit: cover;
								height: 100%;
								width: 100%;
							}
						}
					}
				}
			}

			.presentWrapper {
				display: none;
			}

			@media ${mediaQueryMax.lg2} {
			}
			@media ${mediaQueryMax.md} {
				.imgsWrapper {
					ul {
						flex-wrap: wrap;

						li {
							width: 100%;
							margin: 0 0 50px;

							h3 {
								font-size: 25px;
								line-height: 32px;
								padding-bottom: 20px;
							}

							> p {
								color: ${colors.black00};
								font-size: 15px;
								line-height: 20px;
							}

							> a {
								margin-bottom: 10px;
							}
						}
					}
				}
			}
		}

		// RESORT INFO EXTRA
	}
`;
