import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { navigate } from "gatsby-plugin-intl";
import PropTypes from "prop-types";

const Map = ({ lat, long, zoom, id }) => {
	const { edges: centros } = useStaticQuery(graphql`
		{
			allInternalCentros(filter: { alternative_id: { ne: null } }) {
				edges {
					node {
						intl {
							title {
								ca
								en
								es
								fr
							}
						}
						lat
						long
						name
						title
					}
				}
			}
		}
	`).allInternalCentros;

	let m = null;

	const initMap = () => {
		const { L } = global;

		m = L.map(id, {
			zoomControl: true,
		}).setView([lat, long], zoom);

		m.scrollWheelZoom.disable();
		m.dragging.disable();

		m.on("focus", () => {
			m.scrollWheelZoom.enable();
			m.dragging.enable();
		});
		m.on("blur", () => {
			m.scrollWheelZoom.disable();
			m.dragging.disable();
		});

		L.tileLayer(
			`
		https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
		`,
			{
				attribution:
					'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
			},
		).addTo(m);

		const markers = L.markerClusterGroup({
			iconCreateFunction: (cluster) => {
				return L.divIcon({
					className: "clusterIcon",
					html: cluster.getChildCount(),
					iconSize: [40, 40],
				});
			},
		});

		const goToCentro = (centro) => {
			navigate(`/resort/${centro.name}`);
		};

		centros.forEach(({ node: centro }) => {
			markers.addLayer(
				L.marker([centro.lat, centro.long], {
					icon: L.icon({
						iconUrl: "/images/marker.png",
						iconSize: [30, 30],
					}),
				})
					.bindTooltip(centro.title, {
						permanent: true,
						direction: "bottom",
						offset: [0, 18],
						className: "iconTooltip",
					})
					.on("click", () => {
						goToCentro(centro);
					}),
			);
		});

		m.addLayer(markers);
	};

	useEffect(() => {
		initMap();
	}, []);

	return <div id={id} style={{ height: "100%" }}></div>;
};

Map.propTypes = {
	lat: PropTypes.any.isRequired,
	long: PropTypes.any.isRequired,
	zoom: PropTypes.any,
	style: PropTypes.object,
};

Map.defaultProps = {
	zoom: 14,
};

export default Map;
