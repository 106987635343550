import React from "react";
import { ForecastAlturasListWrapper } from "./styled";
import { useIntl } from "gatsby-plugin-intl";
import { appConfig } from "../../redux/app/actions";
import { connect } from "react-redux";

const ForecastAlturasList = ({ staticCentro, altura, units, appConfig }) => {
	const intl = useIntl();
	return (
		<ForecastAlturasListWrapper>
			<ul>
				<li>
					<button
						onClick={() => appConfig({ altura: 1 })}
						className={altura === 1 ? "active" : ""}
					>
						{units === "m" ? (
							<>
								{staticCentro.altura_1_m}
								{intl.formatMessage({
									id: "unidades.metros_short",
								})}
							</>
						) : (
							<>
								{staticCentro.altura_1_f}
								{intl.formatMessage({
									id: "unidades.pies_short",
								})}
							</>
						)}
					</button>
				</li>
				<li>
					<button
						onClick={() => appConfig({ altura: 2 })}
						className={altura === 2 ? "active" : ""}
					>
						{units === "m" ? (
							<>
								{staticCentro.altura_2_m}
								{intl.formatMessage({
									id: "unidades.metros_short",
								})}
							</>
						) : (
							<>
								{staticCentro.altura_2_f}
								{intl.formatMessage({
									id: "unidades.pies_short",
								})}
							</>
						)}
					</button>
				</li>
				<li>
					<button
						onClick={() => appConfig({ altura: 3 })}
						className={altura === 3 ? "active" : ""}
					>
						{units === "m" ? (
							<>
								{staticCentro.altura_3_m}
								{intl.formatMessage({
									id: "unidades.metros_short",
								})}
							</>
						) : (
							<>
								{staticCentro.altura_3_f}
								{intl.formatMessage({
									id: "unidades.pies_short",
								})}
							</>
						)}
					</button>
				</li>
			</ul>
		</ForecastAlturasListWrapper>
	);
};

const mapStateToProps = (state) => {
	const { altura, units } = state.app.config;
	return {
		altura,
		units,
	};
};

const mapDispatchToProps = {
	appConfig,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ForecastAlturasList);
