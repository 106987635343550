import React from "react";
import { WebCamsWrapper, VideoWrapper, VideoInfo } from "./styled";
import { ReactSVG } from "react-svg";
import {
	CustomButton,
	CustomLinkExternal,
} from "../../assets/styles/globalCommon";
import { appModal } from "../../redux/app/actions";
import { connect } from "react-redux";
import { useIntl } from "gatsby-plugin-intl";

const WebCams = (props) => {
	const { firtWebcam = null, authResolved, loggedIn, appModal } = props;
	const intl = useIntl();

	const openWebcamModal = () => {
		if (authResolved)
			!loggedIn
				? appModal("popuplogin", { campaign: "signup" })
				: appModal("popupwebcam", {});
	};

	const renderWebcamPreview = () => {
		if (firtWebcam.thumbnail_url) {
			return <img src={firtWebcam.thumbnail_url} alt={firtWebcam.title} />;
		} else if (firtWebcam.imagen_url !== "") {
			return <img src={firtWebcam.imagen_url} alt={firtWebcam.title} />;
		} else if (firtWebcam.video_url !== "") {
			return (
				<video width="320" height="180" muted autoPlay playsInline>
					<source
						src={firtWebcam.video_url}
						type="application/x-mpegURL"
					/>
				</video>
			);
		} else if (firtWebcam.iframe_url !== "") {
			return (
				<iframe
					width="320"
					height="180"
					title={firtWebcam.title}
					src={firtWebcam.iframe_url}
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					style={{ border: "none" }}
					allowFullScreen
				/>
			);
		} else {
			return null;
		}
	};

	return (
		<>
			{firtWebcam !== null && (
				<WebCamsWrapper>
					<VideoWrapper>
						<div className="topBar">
							<CustomButton
								onClick={openWebcamModal}
								className="onlyIcon ghost"
							>
								<ReactSVG wrapper="span" src="/img/icon-magnify.svg" />
							</CustomButton>
							<CustomLinkExternal
								href={
									firtWebcam.source_url ? firtWebcam.source_url : "#"
								}
								target="_blank"
								rel="noreferrer"
								className="ghost"
							>
								<p>
									@
									{firtWebcam.source ? firtWebcam.source : "undefined"}
								</p>
							</CustomLinkExternal>
						</div>
						<button
							onClick={openWebcamModal}
							className="gradient"
							aria-label="open-modal"
						/>
						<div className="videoPreview">{renderWebcamPreview()}</div>
					</VideoWrapper>
					<VideoInfo>
						<p>
							{firtWebcam.intl.title[intl.locale]
								? firtWebcam.intl.title[intl.locale]
								: "Fuente no disponible"}
						</p>

						<CustomButton onClick={openWebcamModal} className="withIcon">
							<p>Ver mas webcams</p>
							<ReactSVG
								src="/img/icons/icon-lock.svg"
								wrapper="span"
								className="icon"
							/>
						</CustomButton>
					</VideoInfo>
				</WebCamsWrapper>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		authResolved: state.auth.resolved,
		loggedIn: state.auth.loggedIn,
	};
};

const mapDispatchToProps = {
	appModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebCams);
