import React from "react";
import { connect } from "react-redux";
import { useIntl } from "gatsby-plugin-intl";
import CentroListItem from "../Common/CentroListItem";
import { NearestCentrosWrapper } from "./styled";

const NearestCentros = ({ staticCentro, units, centros }) => {
	const intl = useIntl();
	return (
		<NearestCentrosWrapper>
			<div className="title">
				<h5>Estaciones cercanas</h5>
				<p>a {staticCentro.intl.title[intl.locale]}</p>
			</div>

			<div className="stationList">
				{centros.map((centro, index) => {
					if (centro.id !== staticCentro.id) {
						return (
							<CentroListItem
								centro={centro}
								units={units}
								key={index}
							/>
						);
					} else {
						return null;
					}
				})}
			</div>
		</NearestCentrosWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		units: state.app.config.units,
		centros: state.centro.cercanos,
	};
};

export default connect(mapStateToProps)(NearestCentros);
