import React from "react";
import {
	TarifasFooter,
	TarifasHeader,
	TarifasList,
	TarifasWrapper,
} from "./styled";
import {
	CustomButtonWrapper,
	CustomLinkExternal,
} from "../../assets/styles/globalCommon";
import { ReactSVG } from "react-svg";

const Tarifas = ({ tarifas }) => {
	if (!tarifas) return null;
	return (
		<TarifasWrapper>
			<TarifasHeader>
				<h5>Tarifas Forfait</h5>
				<p>{tarifas.subtitulo}</p>
			</TarifasHeader>
			<TarifasList>
				<div className="tarifasItem">
					<p></p>
					{/*<p>*/}
					{/*	<span>Precio online</span>*/}
					{/*</p>*/}
					<p>
						<span>Precio</span>
					</p>
				</div>
				<div className="tarifasScroll">
					{tarifas?.tarifas.map((tarifa, index) => {
						return (
							<div className="tarifasItem" key={index}>
								<p>{tarifa.title && tarifa.title}</p>
								{/*<p>{tarifa.tarifa_online && tarifa.tarifa_online}</p>*/}
								<p>
									{tarifa.tarifa_taquilla && tarifa.tarifa_taquilla}
								</p>
							</div>
						);
					})}
				</div>
			</TarifasList>
			<TarifasFooter>
				<p>Precio sujeto a actualizaciones.</p>

				<CustomButtonWrapper className={`${!tarifas.url && "disabled"}`}>
					<CustomLinkExternal
						href={tarifas.url ? tarifas.url : "#"}
						target="_blank"
						rel="noreferrer"
						className={`withIcon ${!tarifas.url && "disabled"}`}
					>
						<p>Comprar Forfait</p>
						<ReactSVG
							src="/img/icons/icon-credit-card.svg"
							wrapper="span"
							className="icon"
						/>
					</CustomLinkExternal>
				</CustomButtonWrapper>
			</TarifasFooter>
		</TarifasWrapper>
	);
};

export default Tarifas;
