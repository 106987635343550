import React from "react";
import ContentLoader from "react-content-loader";
import { colors } from "../../assets/styles/vars";

const StarsSkeleton = (props) => (
	<ContentLoader
		speed={2}
		width={140}
		height={20}
		viewBox="0 0 140 20"
		backgroundColor={colors.white}
		foregroundColor={colors.gray70}
		{...props}
	>
		<rect x="0" y="0" rx="0" ry="0" width="20" height="20" />
		<rect x="25" y="0" rx="0" ry="0" width="20" height="20" />
		<rect x="50" y="0" rx="0" ry="0" width="20" height="20" />
		<rect x="75" y="0" rx="0" ry="0" width="20" height="20" />
		<rect x="100" y="0" rx="0" ry="0" width="20" height="20" />
	</ContentLoader>
);

export default StarsSkeleton;
