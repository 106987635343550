import React from "react";
import { ReactSVG } from "react-svg";
import _ from "lodash";
import { StarItem, StarsWrapper } from "./styled";
import StarsSkeleton from "./StarsSkeleton";
import ReactTooltip from "react-tooltip";

const Stars = ({ loaded, value, tooltip = false, tooltipText = "" }) => {
	if (!_.isInteger(value)) {
		value = 0;
	} else {
		if (value < 0) value = 0;
		if (value > 10) value = 10;
		if (value > 0) value = Math.round(value / 2);
	}

	if (!loaded) return <StarsSkeleton />;

	return (
		<StarsWrapper data-tip data-for="stars-tooltip">
			{_.range(0, 5).map((starNumber) => {
				return (
					<StarItem
						key={starNumber}
						className={starNumber < value ? "active" : ""}
					>
						{starNumber < value ? (
							<ReactSVG
								key={starNumber}
								src="/img/icons/icon-star.svg"
								wrapper="span"
								className="icon"
							/>
						) : (
							<ReactSVG
								key={starNumber}
								src="/img/icons/icon-star-outline.svg"
								wrapper="span"
								className="icon"
							/>
						)}
					</StarItem>
				);
			})}

			{tooltip && (
				<ReactTooltip
					id="stars-tooltip"
					place="right"
					type="light"
					effect="solid"
				>
					<span>{tooltipText}</span>
				</ReactTooltip>
			)}
		</StarsWrapper>
	);
};

export default Stars;
