import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import "swiper/css";
import PremiumBlock from "../Common/PremiumBlock";
import ExtendedForecastDaySkeleton from "../ExtendedForecastDay/ExtendedForecastDaySkeleton";
import ExtendedForecastDay from "../ExtendedForecastDay";

const ExtendedForecastDaysSlider = ({
	centroDias,
	altura,
	units,
	isPremium,
	sliderRef,
	setSwiperRef,
}) => {
	const sliderParams = {
		spaceBetween: 0,
		slidesPerView: "auto",
		freeMode: { enabled: true, sticky: false },
		modules: [FreeMode],
	};
	const [currentSlide, setCurrentSlide] = useState(0);

	const renderSwiperSkeleton = () => {
		return (
			<div className="skeletonWrapper">
				{[...Array(9).keys()].map((num) => {
					return <ExtendedForecastDaySkeleton key={num} />;
				})}
			</div>
		);
	};

	return (
		<div className="extendedForecastDays sliderDaysWrapper">
			{!centroDias || centroDias.length === 0 ? (
				renderSwiperSkeleton()
			) : (
				<Swiper onSwiper={setSwiperRef} {...sliderParams}>
					{centroDias.map((dia, index) => {
						return (
							<SwiperSlide key={index} className="swiperSlide">
								<ExtendedForecastDay
									centroDayIndex={index}
									centroDay={dia}
									centroDias={centroDias}
									altura={altura}
									units={units}
									sliderRef={sliderRef}
									currentSlide={currentSlide}
									setCurrentSlide={setCurrentSlide}
								/>
							</SwiperSlide>
						);
					})}
					{!isPremium && (
						<SwiperSlide key="7" className="swiperSlide">
							<PremiumBlock />
						</SwiperSlide>
					)}
				</Swiper>
			)}
		</div>
	);
};

export default ExtendedForecastDaysSlider;
