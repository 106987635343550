import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";

const getCotaDiff = (cota1, cota2) => {
	const diff = Math.abs((cota1 - cota2) / 2);
	return cota1 > cota2 ? cota1 - diff : cota1 + diff;
};

export const calculateGetCotas = (yesterday8, tomorrow8, today8, franjas) => {
	const first =
		yesterday8?.length > 0
			? yesterday8[yesterday8.length - 1][1].cota_m
			: 3000;
	const firstDiff = parseInt(getCotaDiff(first, today8[0][1].cota_m));

	const last = tomorrow8?.length > 0 ? tomorrow8[0][1].cota_m : 3000;
	const lastDiff = parseInt(
		getCotaDiff(last, today8[today8.length - 1][1].cota_m),
	);

	const middle = franjas.map((franja) => franja[1].cota_m);

	return {
		firstDiff,
		lastDiff,
		middle,
	};
};

export const transpolateNieveToPx = (nieve) => {
	const maxNieve = 40;
	if (nieve > maxNieve) nieve = maxNieve;
	return Math.round((124 * nieve) / maxNieve);
};

export const getTemperaturaClass = (temperatura) => {
	if (temperatura <= -7) {
		return "nivel0";
	} else if (temperatura <= -4) {
		return "nivel1";
	} else if (temperatura <= -1) {
		return "nivel2";
	} else if (temperatura <= 3) {
		return "nivel3";
	} else if (temperatura <= 7) {
		return "nivel4";
	} else if (temperatura <= 11) {
		return "nivel5";
	} else {
		return "nivel6";
	}
};

export const getMuchoViento = (viento) => {
	if (viento >= 60) return "bad";
	return null;
};

export const getMuchasRafagas = (rafagas) => {
	if (rafagas >= 60) return "bad";
	return null;
};

export const getVisibilidadString = (visibilidad, intl) => {
	if (visibilidad <= 3) {
		return intl.formatMessage({ id: "clima.visibilidad_mala" });
	} else if (visibilidad <= 6) {
		return intl.formatMessage({ id: "clima.visibilidad_media" });
	} else {
		return intl.formatMessage({ id: "clima.visibilidad_buena" });
	}
};

export const getCielo = (codigo) => {
	switch (codigo) {
		case 113:
		case "113_night":
			return <FormattedMessage id="estado_del_cielo.despejado" />;
		case 116:
		case "116_night":
			return <FormattedMessage id="estado_del_cielo.parcialmente_nublado" />;
		case 119:
		case "119_night":
			return <FormattedMessage id="estado_del_cielo.nublado" />;
		case 122:
			return <FormattedMessage id="estado_del_cielo.cielo_cubierto" />;
		case 143:
			return <FormattedMessage id="estado_del_cielo.ventoso" />;
		case 260:
			return <FormattedMessage id="estado_del_cielo.nieve_ventoso" />;
		case 230:
		case 248:
			return <FormattedMessage id="estado_del_cielo.nublado_ventoso" />;
		case 386:
			return (
				<FormattedMessage id="estado_del_cielo.tormenta_lluvia_moderada" />
			);
		case 389:
			return <FormattedMessage id="estado_del_cielo.tormenta_lluvia" />;
		case 392:
			return (
				<FormattedMessage id="estado_del_cielo.tormenta_nieve_moderada" />
			);
		case 395:
			return <FormattedMessage id="estado_del_cielo.tormenta_nieve" />;
		case 200:
			return <FormattedMessage id="estado_del_cielo.tormenta_electrica" />;
		case 299:
		case 302:
		case 305:
		case 308:
		case 359:
			return <FormattedMessage id="estado_del_cielo.lluvia_intensa" />;
		case 182:
		case 185:
		case 362:
		case 374:
			return <FormattedMessage id="estado_del_cielo.agua_nieve_moderada" />;
		case 176:
		case 266:
		case 263:
		case 293:
		case 296:
		case 353:
		case 356:
			return <FormattedMessage id="estado_del_cielo.lluvia" />;
		case 365:
		case 377:
			return <FormattedMessage id="estado_del_cielo.agua_nieve" />;
		case 311:
		case 317:
		case 350:
			return <FormattedMessage id="estado_del_cielo.nieve_debil_moderada" />;
		case 281:
		case 284:
		case 314:
		case 320:
			return <FormattedMessage id="estado_del_cielo.nieve_debil" />;
		case 179:
		case 227:
		case 323:
		case 326:
		case 329:
		case 332:
		case 335:
		case 338:
		case 368:
		case 371:
			return <FormattedMessage id="estado_del_cielo.nieve" />;
		default:
			return "";
	}
};
