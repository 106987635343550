import React, { useRef, useEffect } from "react";
import { colors } from "../../assets/styles/vars";
import { remap } from "../../utils/utils";

const CotasCanvas = (props) => {
	const canvasRef = useRef(null);
	const { height, cotas } = props;

	const maxCota = 5000;
	const minCota = 0;

	const resizeCota = (value, height) => {
		const remappedValue = remap(value, minCota, maxCota, height, 0);
		return Math.round(remappedValue);
	};

	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas.getContext("2d");
		// POINTS
		const firstPoint = {
			x: 0,
			y: resizeCota(cotas.firstDiff, canvas.height),
		};
		const lastPoint = {
			x: canvas.width,
			y: resizeCota(cotas.lastDiff, canvas.height),
		};
		const middlePoints = cotas.middle.map((value, index) => {
			let delta = 0;
			const multiplier =
				(1 / (cotas.middle.length + 1)) * (index + 1) + delta;
			return {
				x: canvas.width * multiplier,
				y: resizeCota(value, canvas.height),
			};
		});

		// GRADIENT
		context.beginPath();
		context.moveTo(0, canvas.height);
		context.lineTo(firstPoint.x, firstPoint.y);
		for (let i = 0; i < middlePoints.length; i++) {
			context.lineTo(middlePoints[i].x, middlePoints[i].y);
		}
		context.lineTo(lastPoint.x, lastPoint.y);
		context.lineTo(canvas.width, canvas.height);
		context.closePath();
		// const grd = context.createLinearGradient(0, 0, 0, canvas.height);

		// const grdY0 = getHigherPoint(points);
		const grdY0 = canvas.height * 0.3;
		const grd = context.createLinearGradient(0, grdY0, 0, canvas.height);

		grd.addColorStop(0, "rgba(255, 230, 0, 0.3)");
		grd.addColorStop(0.5, "rgba(12, 253, 242, 0.3)"); //${colors.main}
		grd.addColorStop(1, "rgba(12, 253, 242, 0)");

		context.fillStyle = grd;
		context.fill();

		// COTAS
		// 16% bar width
		// 12.5% padding
		// 74% content

		context.beginPath();
		context.moveTo(firstPoint.x, firstPoint.y);
		for (let i = 0; i < middlePoints.length; i++) {
			context.lineTo(middlePoints[i].x, middlePoints[i].y);
		}
		context.lineTo(lastPoint.x, lastPoint.y);

		context.lineWidth = 1.5;
		context.strokeStyle = colors.secondary;
		context.stroke();

		return () => {
			context.clearRect(0, 0, canvas.width, canvas.height);
		};
	}, [cotas]);

	return <canvas height={height} ref={canvasRef} />;
};

export default CotasCanvas;
