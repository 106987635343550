import React, { useEffect, useRef, useState } from "react";
import { ActionButtonWrapper } from "./styled";
import ActionSkeleton from "./ActionSkeleton";
import ReactTooltip from "react-tooltip";
import { appModal } from "../../../redux/app/actions";
import { alertaAdd, alertaDelete } from "../../../redux/alertas/actions";
import { connect } from "react-redux";
import { useIntl } from "gatsby-plugin-intl";
import { renderIcon, renderToolTip } from "./button-utils";
import { CustomButton } from "../../../assets/styles/globalCommon";

const AlertButton = (props) => {
	const {
		centroId,
		showToolTip = false,
		isActive = false,
		layout = "outline",
		withText = false,
		loggedIn,
		isPremium,
		alertasCurrentId,
		alertaAdd,
		alertaDelete,
		appModal,
	} = props;
	const intl = useIntl();

	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		if (alertasCurrentId !== centroId) {
			setIsLoading(false);
		} else {
			setIsLoading(true);
		}
	}, [alertasCurrentId, centroId]);

	const tooltipRef = useRef();
	useEffect(() => {
		if (tooltipRef.current) ReactTooltip.show(tooltipRef.current);
	}, []);

	const addAlertaHandler = () => {
		if (!showToolTip) {
			if (!loggedIn) {
				appModal("popuplogin", { campaign: "signup" });
			} else {
				if (!isPremium) {
					appModal("popuppremium", { campaign: "signup" });
				} else {
					if (isActive) {
						alertaDelete(intl.locale, centroId);
					} else {
						alertaAdd(intl.locale, centroId);
					}
				}
			}
		}
	};

	return (
		<ActionButtonWrapper withText={withText}>
			<CustomButton
				className={`ghost ${
					!withText ? "onlyIcon" : "withUnderline withIcon"
				}`}
				onClick={() => {
					if (!isLoading) addAlertaHandler();
				}}
			>
				{withText && <p>{isActive ? "Quitar Alerta" : "Crear Alerta"}</p>}
				{isActive &&
					showToolTip &&
					renderToolTip(
						"Con solo clicar en la campana podrás habilitar o deshabilitar una alerta 😉.",
						tooltipRef,
					)}
				{isLoading ? (
					<span className="icon">
						<span>
							<ActionSkeleton />
						</span>
					</span>
				) : (
					renderIcon(layout, isActive, "bell")
				)}
			</CustomButton>
		</ActionButtonWrapper>
	);
};

const mapStateToProps = (state) => {
	const { currentId: alertasCurrentId } = state.alertas;
	return {
		loggedIn: state.auth.loggedIn,
		isPremium: state.auth.usuario?.premium,
		alertasCurrentId,
	};
};

const mapDispatchToProps = {
	appModal,
	alertaAdd,
	alertaDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertButton);
