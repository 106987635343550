import styled from "styled-components";
import { colors, mediaQueryMax } from "../../assets/styles/vars";
import { CustomButtonWrapper } from "../../assets/styles/globalCommon";

export const AdImage = styled.div`
	position: relative;
	max-height: 72%;
	flex: 0 0 72%;
	@media ${mediaQueryMax.lg2} {
		max-height: 65%;
		flex: 0 0 65%;
	}
	@media ${mediaQueryMax.md} {
		max-height: none;
		flex: 0;
	}
	img.bgImg {
		object-fit: cover;
		height: 100%;
		width: 100%;
		@media ${mediaQueryMax.md} {
			min-height: 250px;
			max-height: 300px;
		}
	}

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 55%;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.89) 100%
		);
		opacity: 0.5;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 1;
		transition: opacity 0.3s ease-in-out;
	}

	.etiqueta {
		position: absolute;
		top: 0;
		padding: 20px 25px;
		width: 100%;
		box-sizing: border-box;
		z-index: 1;
		@media ${mediaQueryMax.lg} {
			padding: 10px 15px;
		}
		@media ${mediaQueryMax.md} {
			padding: 20px 25px;
		}
		@media ${mediaQueryMax.sm} {
			padding: 15px 15px 0;
		}
		h4 {
			line-height: 1;
			color: ${colors.white};
			text-transform: uppercase;
			background: ${colors.black00};
			padding: 15px 10px;
			border-radius: 5px;
			text-align: center;
			display: block;
		}
	}
`;

export const StationAdWrapper = styled.div`
	background-color: ${colors.gray90};
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	height: 100%;
	@media ${mediaQueryMax.md} {
		display: block;
		height: auto;
	}
	> a {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 5;
	}
	&:hover {
		${AdImage} {
			&:before {
				opacity: 0.8;
			}
		}
	}
`;

export const AdForfait = styled.div`
	position: absolute;
	bottom: 0;
	z-index: 1;
	padding: 0 25px 30px;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	@media ${mediaQueryMax.lg} {
		padding: 0 15px 10px;
	}
	@media ${mediaQueryMax.md} {
		padding: 0 25px 30px;
	}
	@media ${mediaQueryMax.sm} {
		padding: 0 15px 15px;
	}
	.priceBox {
		justify-content: space-evenly;
		.price {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 15px;
			.oldPrice {
				color: ${colors.white};
			}
			.specialPrice {
				color: ${colors.main};
			}
			span.icon {
				width: 20px;
				transform: rotate(-90deg);
			}
		}
		.dayForfaitText {
			font-size: 13px;
			color: ${colors.white};
			text-transform: uppercase;
		}
	}
	img.logo {
		max-height: 70px;
		@media ${mediaQueryMax.lg} {
			max-height: 50px;
		}
		@media ${mediaQueryMax.md} {
			max-height: 70px;
		}
		@media ${mediaQueryMax.sm} {
			max-height: 50px;
		}
	}
`;

export const AdText = styled.div`
	padding: 20px 25px;
	z-index: 1;
	width: 100%;
	max-height: 28%;
	flex: 0 0 28%;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	@media ${mediaQueryMax.lg2} {
		max-height: 35%;
		flex: 0 0 35%;
	}
	@media ${mediaQueryMax.lg} {
		padding: 15px;
	}
	@media ${mediaQueryMax.md} {
		max-height: none;
		flex: 0;
		padding: 20px 25px;
	}
	@media ${mediaQueryMax.sm} {
		padding: 15px;
	}
	${CustomButtonWrapper} {
		width: 100%;
	}
	a {
		width: 100%;
		p {
			margin: 0;
		}
	}
	p {
		margin: 8px 0 0;
		small {
			color: ${colors.white};
		}
	}
`;
