import styled from "styled-components";
import { colors, mediaQueryMax } from "../../assets/styles/vars";

export const ForecastAlturasListWrapper = styled.div`
	ul {
		list-style: none;
		height: 100%;
		margin: 0 0 0 15px;
		padding: 0;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		gap: 22px;
		@media ${mediaQueryMax.md} {
			margin: 0;
		}
		@media (max-width: 540px) {
			gap: 12px;
		}

		li {
			margin-bottom: 24px;
			@media ${mediaQueryMax.md} {
				margin-bottom: 0;
			}

			&:first-child {
				margin-left: 0;
			}

			button {
				cursor: pointer;
				display: block;
				font-size: 16px;
				line-height: 1;
				color: #a6a6a6;
				position: relative;
				text-decoration: none;
				padding-bottom: 3px;
				@media ${mediaQueryMax.md} {
					font-size: 15px;
					padding-bottom: 0;
				}

				&:after {
					opacity: 0;
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 1px;
					background-color: ${colors.white};
					transition: opacity 0.3s ease;
				}

				&:hover,
				&.active {
					color: ${colors.white};

					&:after {
						opacity: 1;
					}
				}
			}
		}
	}
`;
