import React from "react";
import { connect } from "react-redux";
import CustomScroll from "react-custom-scroll";
import { appModal } from "../../redux/app/actions";
import {
	LastReviewsFooter,
	LastReviewsList,
	LastReviewsListWrapper,
	LastReviewsWrapper,
} from "./styled";
import { CustomButton } from "../../assets/styles/globalCommon";
import { ReactSVG } from "react-svg";
import { SliderButton } from "@typeform/embed-react";
import { useIntl } from "gatsby-plugin-intl";
import Stars from "../Stars";
import { getDeporteImgPerfil } from "../../utils/usuario";

const LastReviews = ({
	staticCentro,
	dynamicCentro,
	units,
	authResolved,
	loggedIn,
	usuario,
	appModal,
}) => {
	const intl = useIntl();

	return (
		<LastReviewsWrapper id="last-reviews-wrapper">
			<div className="title">
				<h5>Últimos reviews</h5>
				<p>Últimos reportes de Snowgods y usuarios</p>
			</div>

			<LastReviewsListWrapper>
				<CustomScroll allowOuterScroll>
					<LastReviewsList>
						{dynamicCentro &&
						dynamicCentro.checks &&
						dynamicCentro.checks.length > 0 ? (
							dynamicCentro.checks.map((check, index) => {
								return (
									<div className="lastReviewsItem" key={index}>
										<div className="lastReviewsItemHeader">
											<div className="img">
												{getDeporteImgPerfil(check.usuario)}
											</div>
											<div className="name">
												<h6>
													{check.usuario.nombre}{" "}
													{/*{check.usuario.apellido}*/}
												</h6>
												<p>
													{units === "m"
														? `${check.dia}/${check.mes}/${check.ano}`
														: `${check.mes}/${check.dia}/${check.ano}`}
												</p>
											</div>
											{check.calificacion_centro && (
												<div className="stars">
													<Stars
														loaded={true}
														value={check.calificacion_centro}
													/>
												</div>
											)}
										</div>
										<div className="lastReviewsItemContent">
											<p>{check.comentario}</p>
										</div>
									</div>
								);
							})
						) : (
							<div className="title noReview">
								<h5>No hay reviews disponibles</h5>
								<p>
									¡Se el primero!
									<br />
									Cuéntale a la comunidad el estado de la nieve de{" "}
									<span>{dynamicCentro.title}</span>.
								</p>
							</div>
						)}
					</LastReviewsList>
				</CustomScroll>
			</LastReviewsListWrapper>

			<LastReviewsFooter>
				{authResolved && loggedIn && (
					<SliderButton
						id="jzgBpEWr"
						className="lineLink"
						hidden={{
							centro: staticCentro.id,
							token: localStorage.getItem("token"),
							nombre: usuario?.nombre,
							centro_title: staticCentro.intl.title[intl.locale],
						}}
						size="75"
					>
						<CustomButton className="withIcon">
							<p>Publicar review</p>
							<ReactSVG
								src="/img/icons/icon-check.svg"
								wrapper="span"
								className="icon"
							/>
						</CustomButton>
					</SliderButton>
				)}
				{authResolved && !loggedIn && (
					<CustomButton
						onClick={() => appModal("popuplogin", { campaign: "signup" })}
					>
						<p>Iniciar sesión / registrarse</p>
					</CustomButton>
				)}
			</LastReviewsFooter>
		</LastReviewsWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		units: state.app.config.units,
		authResolved: state.auth.resolved,
		loggedIn: state.auth.loggedIn,
		usuario: state.auth.usuario,
	};
};

const mapDispatchToProps = {
	appModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(LastReviews);
