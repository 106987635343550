import styled from "styled-components";
import { HoursQtySelector } from "../ExtendedForecastDay/styled";
import { colors } from "../../assets/styles/vars";

export const UnitsBarWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #0c0c0c;
	flex: 0 0 80px;
	max-width: 80px;
	transition: left 0.2s linear;
	p {
		font-size: 13px;
		line-height: 18px;
		color: #4a4a4a;
		text-align: center;
	}

	${HoursQtySelector} {
		height: 72px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		p {
			color: ${colors.white};
		}
	}
	.tempConverter {
		height: 124px;
	}

	.cotasHelp {
		height: 125px;
		background-color: #070707;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;
		gap: 14px;
		p {
			//font-size: 11px;
			margin: 0;
		}
	}

	.unitsWrapper {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		gap: 14px;
		margin: 20px 0;
		@media (min-width: 640px) {
			padding: 0 18px;
		}
		.unitsRow {
		}
	}
`;
