import React, { useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "gatsby-plugin-intl";
import PremiumBlock from "../Common/PremiumBlock";
import { ForecastStickyBar } from "./styled";
import ForecastAlturasList from "../ForecastAlturasList";
import ForecastTempsList from "../ForecastTempsList";
import ExtendedForecastDayMobile from "../ExtendedForecastDay/indexMobile";
import {
	ExtendedForecastMobileContent,
	ExtendedForecastMobileWrapper,
} from "./styledMobile";
import { calculateGetCotas } from "../../utils/forecast";

const ExtendedForecastMobile = (props) => {
	const { staticCentro, dynamicCentro, altura, units, isPremium, intl } =
		props;

	const [selectedDay, setSelectedDay] = useState(null);
	const [daysQty, setDaysQty] = useState(7);

	const selectDay = (index) => {
		if (index !== selectedDay) {
			setSelectedDay(index);
		} else {
			setSelectedDay(null);
		}
	};

	return (
		<>
			<ForecastStickyBar>
				<ForecastTempsList layout="horz" />
				<ForecastAlturasList staticCentro={staticCentro} />
			</ForecastStickyBar>
			<ExtendedForecastMobileWrapper>
				<h3>{intl.formatMessage({ id: "centro.pronostico_extendido" })}</h3>

				<ExtendedForecastMobileContent>
					{dynamicCentro &&
						dynamicCentro.clima &&
						dynamicCentro.clima.dias &&
						dynamicCentro.clima.dias
							.filter((dia, index) => {
								if (isPremium) {
									return dia;
								} else if (!isPremium && index < 6) {
									return dia;
								}
							})
							.slice(0, daysQty)
							// .filter((v, i) => i <= 1)
							.map((dia, index) => {
								const centroDias = dynamicCentro.clima.dias;

								return (
									<ExtendedForecastDayMobile
										key={index}
										className={`${
											selectedDay === index ? "active" : ""
										}`}
										onClickHandler={() => selectDay(index)}
										selectedDay={selectedDay}
										centroDayIndex={index}
										centroDay={dia}
										centroDias={centroDias}
										altura={altura}
										units={units}
									/>
								);
							})}

					{!isPremium ? (
						<PremiumBlock layout="horz" />
					) : (
						<div className="daysQtySelector">
							<ul>
								<li className={daysQty === 7 ? "selected" : ""}>
									<a onClick={() => setDaysQty(7)}>
										7 {intl.formatMessage({ id: "clima.dias" })}
									</a>
								</li>
								<li className={daysQty === 14 ? "selected" : ""}>
									<a onClick={() => setDaysQty(14)}>
										14 {intl.formatMessage({ id: "clima.dias" })}
									</a>
								</li>
							</ul>
						</div>
					)}
				</ExtendedForecastMobileContent>
			</ExtendedForecastMobileWrapper>
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { altura, units } = state.app.config;
	return {
		altura,
		units,
		isPremium: state.auth.usuario?.premium,
	};
};

export default connect(
	mapStateToProps,
	null,
)(injectIntl(ExtendedForecastMobile));
