import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { appModal } from "../../redux/app/actions";
import {
	CustomButtonWrapper,
	CustomLinkExternal,
} from "../../assets/styles/globalCommon";
import { AdForfait, AdImage, AdText, StationAdWrapper } from "./styled";
import { ReactSVG } from "react-svg";

const StationAd = ({ ad }) => {
	return (
		ad && (
			<StationAdWrapper>
				{ad.url && <a href={ad.url} target="_blank" rel="noreferrer" />}
				<AdImage>
					{ad.etiqueta && (
						<div className="etiqueta">
							<h4>{ad.etiqueta}</h4>
						</div>
					)}
					{ad.imagen && ad.imagen.url ? (
						<img className="bgImg" src={ad.imagen.url} alt={ad.title} />
					) : (
						<img
							className="bgImg"
							src="/stationad/default.jpg"
							alt={ad.title}
						/>
					)}
					<AdForfait>
						{(ad.precio || ad.oferta) && (
							<>
								<div className="priceBox">
									<div className="price">
										{ad.precio && (
											<h3 className="oldPrice">{ad.precio}</h3>
										)}
										{ad.oferta && (
											<>
												<ReactSVG
													src="/img/arrow.svg"
													wrapper="span"
													className="icon"
												/>
												<h3 className="specialPrice">
													{ad.oferta}
												</h3>
											</>
										)}
									</div>
									<p className="dayForfaitText">
										{ad.descripcion
											? ad.descripcion
											: "FORFAIT DE DÍA"}
									</p>
								</div>

								{/*{ad.logo_mobile && ad.logo_mobile.url && (*/}
								{/*	<source*/}
								{/*		srcSet={ad.logo_mobile.url}*/}
								{/*		media="(max-width: 768px)"*/}
								{/*	/>*/}
								{/*)}*/}
								{ad.logo && ad.logo.url && (
									<img
										className="logo"
										src={ad.logo.url}
										alt={ad.title}
									/>
								)}
							</>
						)}
					</AdForfait>
				</AdImage>
				<AdText>
					<CustomButtonWrapper className={`${!ad.url && "disabled"}`}>
						<CustomLinkExternal
							href={ad.url ? ad.url : "#"}
							target="_blank"
							rel="noreferrer"
							className={`secondary ${!ad.url && "disabled"}`}
						>
							<p>{ad.boton ? ad.boton : "Mapa de Pistas"}</p>
						</CustomLinkExternal>
					</CustomButtonWrapper>
					{ad.boton_subtitle && (
						<p>
							<small>{ad.boton_subtitle}</small>
						</p>
					)}
				</AdText>
			</StationAdWrapper>
		)
	);
};

StationAd.propTypes = {
	ad: PropTypes.object,
};

StationAd.defaultProps = {
	ad: {},
};

const mapDispatchToProps = {
	appModal,
};

export default connect(null, mapDispatchToProps)(StationAd);
