import styled from "styled-components";
import { colors, mediaQueryMax } from "../../assets/styles/vars";

export const LastReviewsWrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	.title {
		padding: 25px;
		border-bottom: 2px solid #0a0a0a;
		h5 {
			color: ${colors.white};
			margin-bottom: 5px;
		}
		p {
			color: #6b6b6b;
			span {
				color: #eaeaea;
				font-weight: 500;
			}
		}
		&.noReview {
			border-bottom: none;
			h5 {
				color: ${colors.main};
			}
		}
	}
`;

export const LastReviewsListWrapper = styled.div`
	flex: 1;
	position: relative;
	overflow: hidden;
	@media ${mediaQueryMax.md} {
		max-height: 300px;
	}
	.rcs-custom-scroll {
		height: 100%;
		.rcs-outer-container {
			height: 100%;
			.rcs-inner-container {
				height: 100%;
				> div {
					height: 100%;
				}
			}
		}
	}
`;

export const LastReviewsList = styled.div`
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;

	.lastReviewsItem {
		padding: 25px 25px 20px;
		border-top: solid 1px #2a2a2a;

		&:first-child {
			border-top: 0;
		}

		.lastReviewsItemHeader {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-bottom: 18px;

			.img {
				width: 40px;
				height: 40px;
				box-sizing: border-box;
				overflow: hidden;
				border-radius: 50%;
				background-color: ${colors.white};
				margin-right: 16px;
				border: 2px solid ${colors.white};

				img {
					width: 100%;
				}
			}

			.name {
				h6 {
					color: ${colors.main};
					margin: 0;
				}

				p {
					color: ${colors.gray84};
					margin: 0;
				}
			}

			.stars {
				margin-left: auto;
			}
		}

		.lastReviewsItemContent {
			p {
				color: ${colors.gray84};
				font-size: 17px;
				font-weight: 400;
				margin: 0;
			}
		}
	}
`;

export const LastReviewsFooter = styled.div`
	padding: 25px;
	box-sizing: border-box;
	a,
	button {
		width: 100%;
	}
`;
