import React, { createRef, useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import { useIntl } from "gatsby-plugin-intl";
import { diaFranjasTemp } from "../../data/franjas-example-data";
import {
	DayBlockMobileWrapper,
	DayBlockMobileHeader,
	DayBlockMobileInfoSlideDown,
} from "./styledMobile";
import "react-slidedown/lib/slidedown.css";
import UnitsBar from "../UnitsBar";
import { CSSTransition } from "react-transition-group";
import ExtendedForecastDayContent from "../ExtendedForecastDayContent";
import { calculateGetCotas } from "../../utils/forecast";

const ExtendedForecastDayMobile = (props) => {
	const {
		selectedDay,
		centroDayIndex,
		centroDay,
		centroDias,
		cotas,
		altura,
		units,
		onClickHandler,
		...rest
	} = props;
	const intl = useIntl();
	const headerItemsRef = useRef();
	const [hoursQty, setHoursQty] = useState(8);

	const franjas = centroDay.alturas[`altura_${altura}`][`franjas${hoursQty}`];
	// console.log(franjas);
	const franjasArray = Object.entries(franjas)
		?.filter((franja) => franja[0] !== "all")
		?.map((v) => [v[0], { ...v[1], nodeRef: createRef() }]); // PARA PROBAR ANIMACION REACT TRANSITION GROUP

	const yesterday8 = centroDias[centroDayIndex - 1]
		? Object.entries(
				centroDias[centroDayIndex - 1].alturas[`altura_${altura}`][
					`franjas8`
				],
		  )?.filter((franja) => franja[0] !== "all")
		: [];

	const tomorrow8 = centroDias[centroDayIndex + 1]
		? Object.entries(
				centroDias[centroDayIndex + 1].alturas[`altura_${altura}`][
					`franjas8`
				],
		  )?.filter((franja) => franja[0] !== "all")
		: [];

	// TO FORCE CONECTION POINTS
	const today8 = Object.entries(
		centroDias[centroDayIndex].alturas[`altura_${altura}`][`franjas8`],
	)?.filter((franja) => franja[0] !== "all");

	const [cotasState, setCotasState] = useState(
		calculateGetCotas(yesterday8, tomorrow8, today8, franjasArray),
	);

	useEffect(() => {
		const cotas = calculateGetCotas(
			yesterday8,
			tomorrow8,
			today8,
			franjasArray,
		);
		setCotasState(cotas);
	}, [hoursQty, altura]);

	const iconoAll = `/images/clima_iconos/${
		centroDay.alturas[`altura_${altura}`].franjas.all.clima_codigo
	}.svg`;

	return (
		<DayBlockMobileWrapper {...rest}>
			<DayBlockMobileHeader
				onClick={onClickHandler}
				role="button"
				tabIndex={centroDayIndex}
				aria-label="select-day"
			>
				<CSSTransition
					in={selectedDay !== centroDayIndex}
					nodeRef={headerItemsRef}
					timeout={300}
					classNames="fade"
					appear
					unmountOnExit
				>
					<div className="headerGroup" ref={headerItemsRef}>
						<div className="headerItem">
							<ReactSVG src={iconoAll} wrapper="span" className="icon" />
						</div>

						<div className="headerItem snow">
							<p>
								<span>
									{units === "m"
										? centroDay.alturas[`altura_${altura}`].franjas8
												.all.nieve_cm +
										  " " +
										  intl.formatMessage({
												id: "unidades.centimetros_short",
										  })
										: centroDay.alturas[`altura_${altura}`].franjas8
												.all.nieve_in +
										  " " +
										  intl.formatMessage({
												id: "unidades.pulgadas_short",
										  })}
								</span>
							</p>
						</div>

						<div className="headerItem tempMinMax">
							<p>
								{units === "m"
									? centroDay.alturas[`altura_${altura}`].franjas.all
											.temperatura_minima_c
									: centroDay.alturas[`altura_${altura}`].franjas.all
											.temperatura_minima_f}
								º/
								{units === "m"
									? centroDay.alturas[`altura_${altura}`].franjas.all
											.temperatura_maxima_c
									: centroDay.alturas[`altura_${altura}`].franjas.all
											.temperatura_maxima_f}
								º
							</p>
						</div>
					</div>
				</CSSTransition>
				<div className="headerGroup">
					<div className="headerItem">
						<p>
							{centroDay.intl.dow_title[intl.locale]} {centroDay.dia}
						</p>
					</div>

					<div
						className={`headerItem arrow ${
							selectedDay === centroDayIndex ? "down" : ""
						}`}
					>
						<ReactSVG
							src="/img/white-arrow.svg"
							wrapper="span"
							className="icon"
						/>
					</div>
				</div>
			</DayBlockMobileHeader>

			<DayBlockMobileInfoSlideDown>
				{selectedDay === centroDayIndex && (
					<>
						<UnitsBar
							units={units}
							isMobile={true}
							hoursQty={hoursQty}
							setHoursQty={setHoursQty}
						/>

						<ExtendedForecastDayContent
							franjas={franjasArray}
							cotas={cotasState}
							units={units}
						/>
					</>
				)}
			</DayBlockMobileInfoSlideDown>
		</DayBlockMobileWrapper>
	);
};

export default ExtendedForecastDayMobile;
