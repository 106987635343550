import styled from "styled-components";
import { ForecastAlturasListWrapper } from "../ForecastAlturasList/styled";
import { colors, mediaQueryMin } from "../../assets/styles/vars";

export const ForecastStickyBar = styled.div`
	position: sticky;
	top: 70px;
	z-index: 1001;
	background-color: ${colors.black00};
	padding: 15px 25px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	${ForecastAlturasListWrapper} {
		li {
			span {
				padding: 0;
			}
		}
	}
`;

export const ExtendedForecastDesktopWrapper = styled.div`
	position: relative;
	padding-top: 0;
	margin-bottom: 1.5%;
	width: 100%;
	overflow: hidden;

	.tempConverter {
		justify-content: center;
		align-items: center;
		height: 123px;
		display: none;
		@media (min-width: 769px) {
			display: flex;
		}
		@media (min-width: 1540px) {
		}

		&.fixed {
			position: fixed;
			top: 80px;
			z-index: 2;
		}
	}
`;

export const ExtendedForecastHeader = styled.div`
	width: 100%;
	height: 75px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	//background-color: rgba(22, 22, 22, 0.8);
	background-color: ${colors.black18};

	.daysQty {
		padding-left: 25px;
		padding-right: 0;
		display: none;
		width: auto;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;

		h3 {
			font-size: 20px;
			line-height: 1;
			color: ${colors.white};
			margin: 0 0 24px;
		}
	}

	.daysHeights {
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
		padding: 0 16px;
		height: 100%;

		img {
			max-height: 57px;
			width: auto;
			display: none;
			//@media (min-width: 769px) {
			@media ${mediaQueryMin.md} {
				display: block;
			}
		}
	}

	.daysArrows {
		margin-left: auto;
		padding: 0 25px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 10px;

		.arrow {
			overflow: hidden;
			border-radius: 5px;
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: ${colors.gray90};
			transition: background-color 0.3s ease-in-out;
			cursor: pointer;

			span.icon {
				width: 20px;

				> span {
					display: flex;
				}

				svg {
					path {
						fill: ${colors.white};
					}
				}
			}

			&:hover {
				background-color: ${colors.main};
			}
		}
	}

	&.fixed {
		background-color: rgba(22, 22, 22, 1);
		position: fixed;
		top: 80px;
		z-index: 1;
	}
`;

export const ExtendedForecastContent = styled.div`
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	position: relative;
	background-color: #161616;

	.extendedForecastDays {
		flex: 1;
		background-color: rgba(23, 23, 23, 0.5);
		height: 100%;
		position: relative;
		overflow: hidden;
		display: block;
		min-height: 600px;

		.swiper-wrapper {
		}
		.swiperSlide {
			height: auto !important;
			width: auto;
			flex: 0 0 100%;
			@media ${mediaQueryMin.sm} {
				flex: 0 0 calc(100% / 2);
			}
			@media ${mediaQueryMin.md} {
				flex: 0 0 calc(100% / 3);
			}
			@media ${mediaQueryMin.lg2} {
				flex: 0 0 calc(100% / 4);
			}
			@media ${mediaQueryMin.xl2} {
				flex: 0 0 calc(100% / 5);
			}
			@media ${mediaQueryMin.xl2} {
				flex: 0 0 calc(100% / 7);
			}

			@media (min-width: 1680px) {
				flex: 0 0
					calc(
						100% /
							${(props) =>
								props.isPremium && props.userLoggedin ? 9 : 7}
					);
			}
		}
	}

	.skeletonWrapper {
		flex: 1;
		height: 638px;
		position: relative;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		div {
			flex: 1;
			width: 200px;
		}
	}
`;
