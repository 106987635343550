import styled, { css } from "styled-components";
import { colors, mediaQueryMin } from "../../assets/styles/vars";

export const DayBlockContent = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	gap: 14px;
	padding-bottom: 20px;
	overflow: hidden;
	// FOR ANIMATION
	@media ${mediaQueryMin.md} {
		transition: width 0.3s;
		min-width: 100%;
		width: ${(props) => props.width && `${props.width}px`};
	}

	// CSS TRANSITION FOR HoursQtySelector BlockInfoRow ITEMS
	.item-enter {
		opacity: 0;
	}
	.item-enter-active {
		opacity: 1;
		transition: opacity 500ms ease-in;
	}
	.item-exit {
		opacity: 1;
	}
	.item-exit-active {
		opacity: 0;
		transition: opacity 500ms ease-out;
	}
`;

export const DayBlockBars = styled.div`
	margin-bottom: 6px;
	.dayBarsItems {
		height: 125px;
		position: relative;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		width: 100%;
		background-color: #070707;

		.dayCanvasWrapper {
			position: absolute;
			height: 100%;
			width: 100%;
			bottom: 0;
			z-index: 2;
			pointer-events: none;

			canvas {
				pointer-events: none;
				width: 100%;
				height: 100%;
			}
		}

		.dayBarsLinesWrapper {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-end;

			.dayBarsLine {
				width: 100%;
				height: 1px;
				background-color: #262626;
			}
		}

		.dayBars {
			z-index: 3;
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			gap: 10px;
			box-sizing: border-box;
			padding: 0 16px;
			@media (min-width: 830px) {
				padding: 0 26px;
			}
			.dayBar {
				width: 34px;
				position: relative;
				overflow: hidden;
				&:after {
					content: "";
					display: block;
					height: 100%;
					width: 18px;
					margin: auto;
					// height: 64px; // seteado en codigo
					background-color: #00fffc;
				}
			}
			.tooltip {
				margin-left: 3px;
			}
		}
	}

	.dayBarsInfo {
		box-sizing: border-box;
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		padding: 0 16px;
		@media (min-width: 830px) {
			padding: 0 26px;
		}

		p {
			min-width: 34px;
			text-align: center;
			font-size: 13px;
			font-weight: normal;
			margin: 0;
			color: #00fffc;
			@media (min-width: 640px) {
				font-size: 18px;
			}
		}
	}
`;

export const BlockInfoRow = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 10px;
	box-sizing: border-box;
	padding: 0 16px;
	@media (min-width: 830px) {
		padding: 0 26px;
	}
	// ICONOS
	&.iconosRow {
		height: 44px;
		// FOR ANIMATION
		align-self: flex-start;
		gap: 24px;
		@media (min-width: 1921px) {
			${(props) =>
				props.hoursQty === 8 &&
				css`
					transition: gap 0.15s;
					gap: 60px;
					align-self: center;
				`}
		}
	}
	> div,
	.iconWrapper {
		width: 35px;
		display: block;
		text-align: center;

		span.icon {
			display: block;
			width: 100%;
			height: 22px;
			margin-bottom: 6px;
			> span {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			svg {
				height: 100%;
				width: auto;
			}
		}

		p {
			font-size: 13px;
			color: ${colors.gray70};
			margin: 0;
		}
		.tooltip {
			margin-left: 3px;
		}
	}

	p {
		min-width: 34px;
		font-size: 15px;
		line-height: 18px;
		text-align: center;
		color: #828282;
		margin: 0;
		&.cota-item {
			min-width: 40px;
		}
		&.visibilidad-item {
			min-width: 40px;
		}

		&.nivel0 {
			color: #bd10e0;
		}

		&.nivel1 {
			color: #9013fe;
		}

		&.nivel2 {
			color: #4a90e2;
		}

		&.nivel3 {
			color: #50e3c2;
		}

		&.nivel4 {
			color: #73dc00;
		}

		&.nivel5 {
			color: #f8e71c;
		}

		&.nivel6 {
			color: #f5a623;
		}

		&.bad {
			color: red;
		}
	}

	.infoArrow {
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 34px;
		height: 17px;
		@media (min-width: 640px) {
			height: 20px;
		}

		.infoArrowLine {
			width: 11px;
			height: 1px;
			background-color: ${colors.white};
		}

		.infoArrowTriangle {
			width: 0;
			height: 0;
			border-top: 4px solid transparent;
			border-bottom: 3px solid transparent;
			border-left: 7px solid ${colors.white};
		}

		&.n {
			transform: rotate(90deg);
		}

		&.nne {
			transform: rotate(${90 + 22.5}deg);
		}

		&.ne {
			transform: rotate(${90 + 22.5 * 2}deg);
		}

		&.ene {
			transform: rotate(${90 + 22.5 * 3}deg);
		}

		&.e {
			transform: rotate(${90 + 22.5 * 4}deg);
		}

		&.ese {
			transform: rotate(${90 + 22.5 * 5}deg);
		}

		&.se {
			transform: rotate(${90 + 22.5 * 6}deg);
		}

		&.sse {
			transform: rotate(${90 + 22.5 * 7}deg);
		}

		&.s {
			transform: rotate(${90 + 22.5 * 8}deg);
		}

		&.ssw {
			transform: rotate(${90 + 22.5 * 9}deg);
		}

		&.sw {
			transform: rotate(${90 + 22.5 * 10}deg);
		}

		&.wsw {
			transform: rotate(${90 + 22.5 * 11}deg);
		}

		&.w {
			transform: rotate(${90 + 22.5 * 12}deg);
		}

		&.wnw {
			transform: rotate(${90 + 22.5 * 13}deg);
		}

		&.nw {
			transform: rotate(${90 + 22.5 * 14}deg);
		}

		&.nnw {
			transform: rotate(${90 + 22.5 * 15}deg);
		}
	}
`;
