import actionTypes from "../actionTypes";

export const alertaAdd = (lang, id) => {
	return {
		type: actionTypes.ALERTA_ADD_INIT,
		payload: { lang, id },
	};
};

export const alertaDelete = (lang, id) => {
	return {
		type: actionTypes.ALERTA_DELETE_INIT,
		payload: { lang, id },
	};
};
