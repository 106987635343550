import React from "react";
import { StyledTooltip } from "../../../assets/styles/pageCommon";
import { ReactSVG } from "react-svg";

export const renderToolTip = (text, ref) => {
	return (
		<div className="tooltipWrapper">
			<div
				ref={ref}
				className="circle icon-tooltip"
				data-tip="React-tooltip"
				data-for="module-tooltip"
			/>
			<StyledTooltip
				id="module-tooltip"
				place="left"
				type="light"
				effect="solid"
				event="click"
				scrollHide={false}
				resizeHide={false}
			>
				<p
					dangerouslySetInnerHTML={{
						__html: text,
					}}
				/>
			</StyledTooltip>
		</div>
	);
};

export const renderIcon = (layout, isActive, iconName) => {
	if (layout === "filled") {
		return (
			<span className={`icon-filled-wrapper ${isActive ? "active" : ""}`}>
				<ReactSVG
					src={`/img/icons/icon-${iconName}-outline.svg`}
					wrapper="span"
					className="icon normal"
				/>
				<ReactSVG
					src={`/img/icons/icon-${iconName}.svg`}
					wrapper="span"
					className="icon hover"
				/>
			</span>
		);
	} else {
		return (
			<ReactSVG
				src={`/img/icons/icon-${iconName}-outline.svg`}
				wrapper="span"
				className={`icon ${isActive ? "active" : ""}`}
			/>
		);
	}
};
