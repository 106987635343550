import React from "react";
import { CentroListItemWrapper, ListItemInfo, ListItemWeather } from "./styled";
import { Link } from "gatsby";
import { ReactSVG } from "react-svg";

const CentroListItem = ({ centro, units }) => {
	return (
		<CentroListItemWrapper>
			<Link to={`/resort/${centro.name}`}>
				<ListItemInfo>
					{/*<div className="highlight">Smart snow</div>*/}
					<p>{centro.title}</p>
					{centro.pais && (
						<p>
							<span>
								{centro.pais.title}
								{centro.distancia_km &&
									`, ${
										units === "m"
											? `${centro.distancia_km} km`
											: `${centro.distancia_miles} mi`
									}`}
							</span>
						</p>
					)}
				</ListItemInfo>
				<ListItemWeather>
					<div className="weather">
						<div className="snow">
							<ReactSVG
								src="/img/snowflake-white.svg"
								wrapper="span"
								className="icon"
							/>
							<p>
								{centro.clima &&
									centro.clima.actual &&
									(units === "m"
										? `${centro.clima.actual.nieve_ultimas_72_cm} cm`
										: `${centro.clima.actual.nieve_ultimas_72_in} in`)}
							</p>
						</div>
						<p>
							<span>Ult. 72 HS</span>
						</p>
					</div>
					<div className="weather">
						<div className="snow">
							<ReactSVG
								src="/img/snowflake-white.svg"
								wrapper="span"
								className="icon"
							/>
							<p>
								{centro.clima &&
									centro.clima.actual &&
									(units === "m"
										? `${centro.clima.actual.nieve_proximas_72_cm} cm`
										: `${centro.clima.actual.nieve_proximas_72_in} in`)}
							</p>
						</div>
						<p>
							<span>Próx. 72 HS</span>
						</p>
					</div>
				</ListItemWeather>
			</Link>
		</CentroListItemWrapper>
	);
};

export default CentroListItem;
