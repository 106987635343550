import styled from "styled-components";
import { colors, sizes } from "../../assets/styles/vars";
import { CustomButton } from "../../assets/styles/globalCommon";

export const WebCamsWrapper = styled.div`
	flex: 1;
	height: 100%;
	margin-left: 1.5%;
	@media (max-width: ${sizes.lg}) {
		flex: 0 0 100%;
		max-width: 100%;
		margin: 0;
	}
`;

export const VideoWrapper = styled.div`
	width: 100%;
	position: relative;
	overflow: hidden;
	&:before {
		content: "";
		display: block;
		padding-top: 56.25%;
	}
	.gradient {
		cursor: pointer;
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			180deg,
			${colors.white} 0%,
			rgba(255, 255, 255, 0) 40%
		);
	}
	.videoPreview {
		z-index: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		img,
		iframe,
		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.topBar {
		z-index: 10;
		position: absolute;
		top: 0;
		width: 100%;
		padding: 12px 10px;
		box-sizing: border-box;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		button {
			margin-left: 6px;
			display: flex;
			span {
				display: flex;
			}
		}
	}
`;

export const VideoInfo = styled.div`
	background-color: ${colors.black0A};
	padding-top: 30px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	gap: 20px;
	align-items: flex-end;
	> p {
		font-size: 15px;
		line-height: 17px;
		color: ${colors.white};
	}
	@media (max-width: ${sizes.lg}) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 30px 0;
	}
	@media (max-width: ${sizes.md}) {
		padding: 20px 25px;
	}
	@media (max-width: ${sizes.sm}) {
		flex-direction: column;
		align-items: flex-end;
		${CustomButton} {
			width: 100%;
			margin: 0;
			box-sizing: border-box;
		}
	}
`;
