import actionTypes from "../actionTypes";

export const favoritoAdd = (lang, id) => {
	return {
		type: actionTypes.FAVORITO_ADD_INIT,
		payload: { lang, id },
	};
};

export const favoritoDelete = (lang, id) => {
	return {
		type: actionTypes.FAVORITO_DELETE_INIT,
		payload: { lang, id },
	};
};
