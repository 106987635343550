import React from "react";
import ForecastTempsList from "../ForecastTempsList";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { UnitsBarWrapper } from "./styled";
import ReactTooltip from "react-tooltip";
import { HoursQtySelector } from "../ExtendedForecastDay/styled";
import { ReactSVG } from "react-svg";

const UnitsBar = (props) => {
	const { units, isMobile = false, hoursQty, setHoursQty } = props;
	const intl = useIntl();

	const getNextHour = (hours) => {
		if (hours === 8) {
			return 3;
		} else if (hours === 3) {
			return 1;
		} else {
			return 8;
		}
	};

	return (
		<UnitsBarWrapper>
			{isMobile ? (
				<HoursQtySelector>
					<button onClick={() => setHoursQty(getNextHour(hoursQty))}>
						<ReactSVG
							data-tip=""
							data-for="icon-expand"
							src="/img/icons/icon-expand.svg"
							className="icon"
							wrapper="span"
						/>
						<p>{getNextHour(hoursQty)}h</p>
						<ReactTooltip
							id="icon-expand"
							place="bottom"
							type="light"
							effect="solid"
							className="tooltip"
						>
							<span>Ampliar Horas</span>
						</ReactTooltip>
					</button>
				</HoursQtySelector>
			) : (
				<div className="tempConverter">
					<ForecastTempsList />
				</div>
			)}

			<div className="cotasHelp" data-tip="" data-for="cotas-help">
				{units === "m" ? (
					<>
						<p>3750m</p>
						<p>2500m</p>
						<p>1250m</p>
					</>
				) : (
					<>
						<p>11250ft</p>
						<p>7500ft</p>
						<p>3750ft</p>
					</>
				)}

				<ReactTooltip
					id="cotas-help"
					place="right"
					type="light"
					effect="solid"
				>
					<span>Cota de Nieve</span>
				</ReactTooltip>
			</div>

			<div className="unitsWrapper">
				{/* NIEVE */}
				<div className="unitsRow">
					<p data-tip="" data-for="nieve-cantidad">
						{units === "m"
							? intl.formatMessage({
									id: "unidades.centimetros_short",
							  })
							: intl.formatMessage({
									id: "unidades.pulgadas_short",
							  })}
					</p>

					<ReactTooltip
						id="nieve-cantidad"
						place="right"
						type="light"
						effect="solid"
					>
						<span>
							{units === "m"
								? intl.formatMessage({
										id: "unidades.centimetros_tooltip",
								  })
								: intl.formatMessage({
										id: "unidades.pulgadas_nieve_tooltip",
								  })}
						</span>
					</ReactTooltip>
				</div>

				{/* PRECIPITACIONES */}
				<div className="unitsRow">
					<p data-tip="" data-for="lluvia-cantidad">
						{units === "m"
							? intl.formatMessage({
									id: "unidades.milimetros_short",
							  })
							: intl.formatMessage({
									id: "unidades.pulgadas_short",
							  })}
					</p>

					<ReactTooltip
						id="lluvia-cantidad"
						place="right"
						type="light"
						effect="solid"
					>
						<span>
							{units === "m"
								? intl.formatMessage({
										id: "unidades.milimetros_tooltip",
								  })
								: intl.formatMessage({
										id: "unidades.pulgadas_lluvia_tooltip",
								  })}
						</span>
					</ReactTooltip>
				</div>

				{/* COTA */}
				<div className="unitsRow">
					<p data-tip="" data-for="cota">
						<FormattedMessage id="clima.cota" />
					</p>

					<ReactTooltip
						id="cota"
						place="right"
						type="light"
						effect="solid"
					>
						<span>
							<FormattedMessage id="clima.cota_tooltip" />
						</span>
					</ReactTooltip>
				</div>

				{/* VISIBILIDAD */}
				<div className="unitsRow">
					<p data-tip="" data-for="visib">
						<FormattedMessage id="clima.visibilidad_abbr" />
					</p>

					<ReactTooltip
						id="visib"
						place="right"
						type="light"
						effect="solid"
					>
						<span>
							<FormattedMessage id="clima.visibilidad_tooltip" />
						</span>
					</ReactTooltip>
				</div>

				{/* VIENTO */}
				<div className="unitsRow">
					<p data-tip="" data-for="viento_veloc">
						{units === "m"
							? intl.formatMessage({ id: "unidades.kmh_short" })
							: intl.formatMessage({
									id: "unidades.mph_short",
							  })}
					</p>

					<ReactTooltip
						id="viento_veloc"
						place="right"
						type="light"
						effect="solid"
					>
						<span>
							{units === "m"
								? intl.formatMessage({
										id: "unidades.kmh_tooltip",
								  })
								: intl.formatMessage({
										id: "unidades.mph_tooltip",
								  })}
						</span>
					</ReactTooltip>
				</div>

				{/* RAFAGAS */}
				<div className="unitsRow">
					<p data-tip="" data-for="rafagas">
						<FormattedMessage id="clima.rafagas_abbr" />
					</p>

					<ReactTooltip
						id="rafagas"
						place="right"
						type="light"
						effect="solid"
					>
						<span>
							<FormattedMessage id="clima.rafagas_tooltip" />
						</span>
					</ReactTooltip>
				</div>

				{/* DIRECCION VIENTO */}
				<div className="unitsRow">
					<p data-tip="" data-for="direccion">
						<FormattedMessage id="clima.direccion_abbr" />
					</p>

					<ReactTooltip
						id="direccion"
						place="right"
						type="light"
						effect="solid"
					>
						<span>
							<FormattedMessage id="clima.direccion_tooltip" />
						</span>
					</ReactTooltip>
				</div>

				{/*EMPTY SPACE*/}
				<div className="unitsRow">
					<p></p>
				</div>

				{/* TEMP MAX */}
				<div className="unitsRow">
					<p data-tip="" data-for="max">
						<FormattedMessage id="clima.max_abbr" />{" "}
						{units === "m"
							? intl.formatMessage({
									id: "unidades.celsius_short_big",
							  })
							: intl.formatMessage({
									id: "unidades.fahrenheit_short_big",
							  })}
					</p>

					<ReactTooltip id="max" place="right" type="light" effect="solid">
						<span>
							<FormattedMessage id="clima.max_tooltip" />
						</span>
					</ReactTooltip>
				</div>

				{/* TEMP MIN */}
				<div className="unitsRow">
					<p data-tip="" data-for="min">
						<FormattedMessage id="clima.min_abbr" />{" "}
						{units === "m"
							? intl.formatMessage({
									id: "unidades.celsius_short_big",
							  })
							: intl.formatMessage({
									id: "unidades.fahrenheit_short_big",
							  })}
					</p>

					<ReactTooltip id="min" place="right" type="light" effect="solid">
						<span>
							<FormattedMessage id="clima.min_tooltip" />
						</span>
					</ReactTooltip>
				</div>

				{/* SENSACION TERMICA */}
				<div className="unitsRow">
					<p data-tip="" data-for="st">
						<FormattedMessage id="clima.st_abbr" />{" "}
						{units === "m"
							? intl.formatMessage({
									id: "unidades.celsius_short_big",
							  })
							: intl.formatMessage({
									id: "unidades.fahrenheit_short_big",
							  })}
					</p>

					<ReactTooltip id="st" place="right" type="light" effect="solid">
						<span>
							<FormattedMessage id="clima.st_tooltip" />
						</span>
					</ReactTooltip>
				</div>
			</div>
		</UnitsBarWrapper>
	);
};

export default UnitsBar;
