import styled from "styled-components";
import { colors } from "../../assets/styles/vars";

export const StarsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 5px;
`;

export const StarItem = styled.div`
	width: 20px;
	height: 20px;
	display: flex;
	svg {
		width: 100%;
		height: auto;
		path {
			fill: ${colors.gray70};
			transition: fill 0.3s ease;
		}
	}
	&.active {
		svg {
			path {
				fill: ${colors.main};
			}
		}
	}
`;
