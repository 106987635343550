import React from "react";
import { ForecastTempsListWrapper } from "./styled";
import { FormattedMessage } from "gatsby-plugin-intl";
import { appConfig } from "../../redux/app/actions";
import { connect } from "react-redux";

const ForecastTempsList = ({ layout, units, appConfig }) => {
	return (
		<ForecastTempsListWrapper layout={layout}>
			<ul>
				<li
					onClick={() => appConfig({ units: "m" })}
					className={units === "m" ? "current" : ""}
				>
					<FormattedMessage id="unidades.celsius_short_big" />
				</li>
				<li
					onClick={() => appConfig({ units: "i" })}
					className={units === "i" ? "current" : ""}
				>
					<FormattedMessage id="unidades.fahrenheit_short_big" />
				</li>
			</ul>
		</ForecastTempsListWrapper>
	);
};

const mapStateToProps = (state) => {
	const { units } = state.app.config;
	return {
		units,
	};
};

const mapDispatchToProps = {
	appConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForecastTempsList);
