class Tools {
	static isUserFavorite(favoritos, centroId) {
		let isFav = false;
		if (favoritos && favoritos.length > 0) {
			favoritos.forEach((favorito) => {
				if (favorito.id === centroId) isFav = true;
			});
		}
		return isFav;
	}
	static isUserAlerta(alertas, centroId) {
		let isAlerta = false;
		if (alertas && alertas.length > 0) {
			alertas.forEach((alerta) => {
				if (alerta.id === centroId) isAlerta = true;
			});
		}
		return isAlerta;
	}
	static getFlagEmoji = (countryCode) => {
		const codePoints = countryCode
			.toUpperCase()
			.split("")
			.map((char) => 127397 + char.charCodeAt());
		return String.fromCodePoint(...codePoints);
	};
}

export default Tools;
