import React, { useState, useEffect, useRef, createRef } from "react";
import { connect } from "react-redux";
import { ReactSVG } from "react-svg";
import { useIntl } from "gatsby-plugin-intl";
import { DayBlockHeader, DayBlockWrapper, HoursQtySelector } from "./styled";
import ReactTooltip from "react-tooltip";
import ExtendedForecastDayContent from "../ExtendedForecastDayContent";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { appModal } from "../../redux/app/actions";
import { calculateGetCotas } from "../../utils/forecast";

const ExtendedForecastDay = (props) => {
	const {
		centroDayIndex,
		centroDay,
		centroDias,
		altura,
		units,
		sliderRef,
		currentSlide,
		setCurrentSlide,
		sliderWidth,
		loggedIn,
		isPremium,
		appModal,
	} = props;
	const intl = useIntl();

	const hoursQtySelectorRef = useRef();
	const [hoursQty, setHoursQty] = useState(8);
	const dayBlockRef = useRef();

	const franjas = centroDay.alturas[`altura_${altura}`][`franjas${hoursQty}`];
	// console.log(franjas);
	const franjasArray = Object.entries(franjas)
		?.filter((franja) => franja[0] !== "all")
		?.map((v) => [v[0], { ...v[1], nodeRef: createRef() }]); // PARA PROBAR ANIMACION REACT TRANSITION GROUP

	const yesterday8 = centroDias[centroDayIndex - 1]
		? Object.entries(
				centroDias[centroDayIndex - 1].alturas[`altura_${altura}`][
					`franjas8`
				],
		  )?.filter((franja) => franja[0] !== "all")
		: [];

	const tomorrow8 = centroDias[centroDayIndex + 1]
		? Object.entries(
				centroDias[centroDayIndex + 1].alturas[`altura_${altura}`][
					`franjas8`
				],
		  )?.filter((franja) => franja[0] !== "all")
		: [];

	// TO FORCE CONECTION POINTS
	const today8 = Object.entries(
		centroDias[centroDayIndex].alturas[`altura_${altura}`][`franjas8`],
	)?.filter((franja) => franja[0] !== "all");

	const [cotasState, setCotasState] = useState(
		calculateGetCotas(yesterday8, tomorrow8, today8, franjasArray),
	);

	useEffect(() => {
		const cotas = calculateGetCotas(
			yesterday8,
			tomorrow8,
			today8,
			franjasArray,
		);
		setCotasState(cotas);
	}, [hoursQty, altura]);

	useEffect(() => {
		if (sliderRef) {
			// sliderRef.update();
			setTimeout(() => {
				sliderRef.update();
			}, 300);
		}
	}, [franjasArray, hoursQty]);

	useEffect(() => {
		if (currentSlide !== centroDayIndex) setHoursQty(8);
	}, [currentSlide]);

	const hoursQtyHandler = (hours) => {
		if (!loggedIn) {
			appModal("popuplogin", { campaign: "signup" });
		} else {
			if (!isPremium) {
				appModal("popuppremium", { campaign: "signup" });
			} else {
				setCurrentSlide(centroDayIndex);
				setHoursQty(hours);
			}
		}
	};

	return (
		<DayBlockWrapper ref={dayBlockRef}>
			<DayBlockHeader>
				<div className="titleWrapper">
					<h4>
						{centroDay.intl.dow_title[intl.locale]} {centroDay.dia}
					</h4>

					<SwitchTransition>
						<CSSTransition
							key={hoursQty === 8}
							nodeRef={hoursQtySelectorRef}
							addEndListener={(done) => {
								hoursQtySelectorRef.current.addEventListener(
									"transitionend",
									done,
									false,
								);
							}}
							classNames="fade"
						>
							<HoursQtySelector ref={hoursQtySelectorRef}>
								{hoursQty === 8 ? (
									<div className="buttonGroup">
										<button onClick={() => hoursQtyHandler(3)}>
											<ReactSVG
												data-tip=""
												data-for={`icon-expand-${centroDayIndex}-${hoursQty}`}
												src="/img/icons/icon-expand.svg"
												className="icon"
												wrapper="span"
											/>
											<ReactTooltip
												id={`icon-expand-${centroDayIndex}-${hoursQty}`}
												place="bottom"
												type="light"
												effect="solid"
												className="tooltip"
											>
												<span>Ampliar Horas</span>
											</ReactTooltip>
										</button>
									</div>
								) : (
									<div className="buttonGroup extended">
										<button
											className={hoursQty === 8 ? "active" : ""}
											onClick={() => hoursQtyHandler(8)}
										>
											8hs
										</button>
										<button
											className={hoursQty === 3 ? "active" : ""}
											onClick={() => hoursQtyHandler(3)}
										>
											3hs
										</button>
										<button
											className={hoursQty === 1 ? "active" : ""}
											onClick={() => hoursQtyHandler(1)}
										>
											1h
										</button>
									</div>
								)}
							</HoursQtySelector>
						</CSSTransition>
					</SwitchTransition>
				</div>
			</DayBlockHeader>

			<ExtendedForecastDayContent
				franjas={franjasArray}
				cotas={cotasState}
				units={units}
				hoursQty={hoursQty}
				sliderWidth={sliderWidth}
			/>
		</DayBlockWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		loggedIn: state.auth.loggedIn,
		isPremium: state.auth.usuario?.premium,
	};
};

const mapDispatchToProps = {
	appModal,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ExtendedForecastDay);
