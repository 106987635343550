import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Imgs from "../components/Imgs";
import Forecast from "../components/ExtendedForecast";
import ForecastMobile from "../components/ExtendedForecast/indexMobile";
import ExtrasBlockGrid from "../components/ExtrasBlockGrid";
import { useIntl } from "gatsby-plugin-intl";
import { Link } from "gatsby";
import { ReactSVG } from "react-svg";
import { connect } from "react-redux";
import { centroGet } from "../redux/centro/actions";
import { appModal } from "../redux/app/actions";
import Tools from "../classes/tools";
import WebCams from "../components/WebCams";
import { CustomButton } from "../assets/styles/globalCommon";
import {
	PageContent,
	PageContentWrapper,
	StyledBgImage,
} from "../assets/styles/pageCommon";
import {
	ResortExtraInfoSection,
	ResortSection,
} from "../assets/styles/pages/pageResort";
import InfoCircles from "../components/Common/InfoCircles";
import Slider from "../components/Common/Slider";
import Stars from "../components/Stars";
import ReactTooltip from "react-tooltip";
import AlertButton from "../components/Common/ActionsButtons/AlertButton";
import FavButton from "../components/Common/ActionsButtons/FavButton";
import PublicarParteButton from "../components/Common/ActionsButtons/PublicarParteButton";
import moment from "moment";

const EstacionPage = ({
	pageContext,
	dynamicCentro,
	climaActual,
	bootLoaded,
	lat,
	long,
	centroGet,
	appModal,
	units,
	favoritos,
	alertas,
	usuario,
	isPremium,
	loggedIn,
	authResolved,
	isMobileResponsive,
	isMobile768,
}) => {
	const { staticCentro } = pageContext;

	const intl = useIntl();

	const [hasWebcams, setHasWebcams] = useState(false);

	useEffect(() => {
		if (bootLoaded)
			setHasWebcams(
				dynamicCentro.webcams && dynamicCentro.webcams.length > 0,
			);
	}, [bootLoaded, dynamicCentro]);

	useEffect(() => {
		if (bootLoaded && authResolved)
			centroGet(intl.locale, {
				id: staticCentro.id,
				lat,
				long,
				include_clima: 1,
				include_checks: 1,
				token: loggedIn ? localStorage.getItem("token") : null,
			});
	}, [bootLoaded, authResolved, loggedIn]);

	const getNieveData = () => {
		if (climaActual) {
			const {
				nieve_ultimas_24_cm,
				nieve_ultimas_24_in,
				nieve_ultimas_48_cm,
				nieve_ultimas_48_in,
				nieve_ultimas_72_cm,
				nieve_ultimas_72_in,
			} = climaActual;

			// armar valor real en cm o in

			let nieve24 = 0;
			let nieve48 = 0;
			let nieve72 = 0;
			let unitName = "";
			if (units === "m") {
				nieve24 = nieve_ultimas_24_cm;
				nieve48 = nieve_ultimas_48_cm;
				nieve72 = nieve_ultimas_72_cm;
				unitName = intl.formatMessage({ id: "unidades.centimetros_short" });
			} else {
				nieve24 = nieve_ultimas_24_in;
				nieve48 = nieve_ultimas_48_in;
				nieve72 = nieve_ultimas_72_in;
				unitName = intl.formatMessage({ id: "unidades.pulgadas_short" });
			}

			// calcular alturas

			const alturaMinima = 32;
			const alturaMaxima = 115;
			const nieveMaxima = 40;

			let altura24 =
				(nieve24 * (alturaMaxima - alturaMinima)) / nieveMaxima +
				alturaMinima;
			let altura48 =
				(nieve48 * (alturaMaxima - alturaMinima)) / nieveMaxima +
				alturaMinima;
			let altura72 =
				(nieve72 * (alturaMaxima - alturaMinima)) / nieveMaxima +
				alturaMinima;

			if (altura24 > alturaMaxima) altura24 = alturaMaxima;
			if (altura48 > alturaMaxima) altura48 = alturaMaxima;
			if (altura72 > alturaMaxima) altura72 = alturaMaxima;

			const data = [
				{
					altura: altura24,
					valor: nieve24.toString() + unitName,
					horas: intl.formatMessage({ id: "unidades.horas_24_short" }),
				},
				{
					altura: altura48,
					valor: nieve48.toString() + unitName,
					horas: intl.formatMessage({ id: "unidades.horas_48_short" }),
				},
				{
					altura: altura72,
					valor: nieve72.toString() + unitName,
					horas: intl.formatMessage({ id: "unidades.horas_72_short" }),
				},
			];

			return data;
		} else {
			return null;
		}
	};

	const nieveData = getNieveData();

	const goToReviews = () => {
		const reviewsElem = document.getElementById("last-reviews-wrapper");
		if (reviewsElem) {
			reviewsElem.scrollIntoView({ block: "center", behavior: "smooth" });
		}
	};

	const fakeHistorico = () => {
		appModal("popuplogin", { campaign: "historical" });
	};

	const fakeWidget = () => {
		appModal("popuplogin", { campaign: "widget" });
	};

	const resortCirclesInfo = [
		{
			label: "Remontes",
			value: staticCentro.remontes_total,
		},
		{
			label: "Pistas",
			value: staticCentro.pistas_total,
		},
		{
			label: "Kilometros",
			value: staticCentro.kilometros,
		},
		{
			label: "Snowparks",
			value: staticCentro.snowparks_total,
		},
	];

	const formatUltimaAlerta = (fecha) => {
		// 22/11/2022 21:24
		if (fecha) {
			const [dia, horario] = fecha.split(" ");
			const [d, m, y] = dia.split("/");
			moment.locale(intl.locale);
			const f = moment([y, m - 1, d]);
			if (f.isValid()) {
				return f.format("ll");
			} else {
				return "";
			}
		} else {
			return "";
		}
	};

	return (
		<Layout>
			<Seo
				title={`El Tiempo en ${
					staticCentro.intl.title[intl.locale]
				} con Webcam`}
				description={`Descubre el tiempo en ${
					staticCentro.intl.title[intl.locale]
				} con nuestras cámaras en tiempo real. ¡Prepárate para un día increíble gracias a Fresh Snow!`}
				calificacionCentro={staticCentro.calificacion_centro}
				reviewsQty={staticCentro?.checks?.length}
			/>

			<ResortSection>
				<PageContentWrapper layout="light" className="resortHeader">
					<StyledBgImage
						bgImageDesktop="/results/bg/main-banner.jpg"
						bgImageMobile="/results/bg/main-banner.jpg"
					/>
					<PageContent width="1280px" paddingTop="0" paddingBottom="0">
						<div className="headerLinks">
							{lat && long ? (
								<Link to="/resorts" className="lineLink">
									<u>Volver a los resultados</u>
								</Link>
							) : (
								<span>&nbsp;</span>
							)}
							<div className="headerAdd">
								<PublicarParteButton
									centroId={staticCentro.id}
									centroTitle={staticCentro.intl.title[intl.locale]}
									usuarioName={usuario?.nombre}
									layout="filled"
									withText={true}
								/>
								<AlertButton
									centroId={staticCentro.id}
									showToolTip={false}
									isActive={Tools.isUserAlerta(
										alertas,
										staticCentro.id,
									)}
									layout="filled"
									withText={true}
								/>
								<FavButton
									centroId={staticCentro.id}
									showToolTip={false}
									isActive={Tools.isUserFavorite(
										favoritos,
										staticCentro.id,
									)}
									layout="filled"
									withText={true}
								/>
							</div>
						</div>

						<div className="headerContent">
							<div className="left">
								<div
									className="starsWrapper"
									onClick={() => goToReviews()}
								>
									<Stars
										loaded={bootLoaded}
										value={dynamicCentro.calificacion_centro}
										tooltip={true}
										tooltipText="Asi puntúan los usuarios a esta estación"
									/>
								</div>
								<h2>{staticCentro.intl.title[intl.locale]}</h2>
								{staticCentro.pais && (
									<p>
										{staticCentro.pais &&
											staticCentro.pais.intl.title[intl.locale]}
										{dynamicCentro && dynamicCentro.distancia_km && (
											<>, {dynamicCentro.distancia_km} km</>
										)}
									</p>
								)}
							</div>
							<div className="right">
								<InfoCircles items={resortCirclesInfo} />
							</div>
						</div>
					</PageContent>
				</PageContentWrapper>

				<PageContentWrapper
					bgColor="transparent"
					layout="dark"
					className="resortMainInfo"
				>
					<PageContent width="1280px" paddingTop="0" paddingBottom="0">
						<div className="mainInfoContent">
							<div
								className={`infoLastSnowWrapper ${
									hasWebcams ? "" : "fullWidth"
								}`}
							>
								<div className="infoLastSnow">
									{bootLoaded && (
										<>
											<div className="info01">
												<ReactSVG
													src="/img/snowflake.svg"
													className="snowflakeIcon"
												/>
												<p>
													{climaActual && (
														<>{climaActual.temperatura_c}º</>
													)}
													<span>
														{staticCentro.intl.title[intl.locale]}
													</span>
												</p>
											</div>
											<div className="info02">
												<ul>
													<li>
														<p className="title">Sens. T</p>
														<p className="number">
															<span>ST</span>
															{climaActual && (
																<>
																	{
																		climaActual.sensacion_termica_c
																	}
																	º
																</>
															)}
														</p>
													</li>
													<li>
														<p className="title">Lluvia</p>
														<p className="number">
															{climaActual && (
																<>
																	{
																		climaActual.precipitaciones_mm
																	}{" "}
																	mm
																</>
															)}
														</p>
													</li>
													<li>
														<p className="title">Amanecer</p>
														<p className="number">
															{climaActual && (
																<>{climaActual.amanecer_24}</>
															)}
														</p>
													</li>
													<li>
														<p className="title">Atardecer</p>
														<p className="number">
															{climaActual && (
																<>{climaActual.atardecer_24}</>
															)}
														</p>
													</li>
													{dynamicCentro.ultima_alerta && (
														<li>
															<p className="title">
																Última nevada
															</p>
															<p className="number">
																{formatUltimaAlerta(
																	dynamicCentro.ultima_alerta,
																)}
															</p>
														</li>
													)}
												</ul>
											</div>
											{/* <div className="info03">
												<p>xx/xx/xxxx</p>
											</div> */}
											<div className="info04">
												<CustomButton
													// onClick={fakeHistorico}
													data-tip="Próximamente"
													data-for="historico-tip"
												>
													<p>Ver Histórico</p>
												</CustomButton>

												<ReactTooltip
													id="historico-tip"
													place="top"
													type="dark"
													effect="solid"
												/>

												{/*<span className="add">*/}
												{/*	<AlertButton*/}
												{/*		centroId={staticCentro.id}*/}
												{/*		showToolTip={false}*/}
												{/*		isActive={Tools.isUserAlerta(*/}
												{/*			alertas,*/}
												{/*			staticCentro.id,*/}
												{/*		)}*/}
												{/*		layout="filled"*/}
												{/*	/>*/}
												{/*	<FavButton*/}
												{/*		centroId={staticCentro.id}*/}
												{/*		showToolTip={false}*/}
												{/*		isActive={Tools.isUserFavorite(*/}
												{/*			favoritos,*/}
												{/*			staticCentro.id,*/}
												{/*		)}*/}
												{/*		layout="filled"*/}
												{/*	/>*/}
												{/*</span>*/}
											</div>
										</>
									)}
								</div>

								<div className="infoLastSnow02">
									<div className="left">
										<h3>
											{climaActual && climaActual.nieve_descripcion}
										</h3>
									</div>
									{nieveData && (
										<div className="right">
											<div className="desktopInfoGrid">
												<h5>
													<ReactSVG
														src="/img/snowflake.svg"
														className="icon"
														wrapper="span"
													/>
													Últimos 3 días
												</h5>
												<ul>
													{nieveData.map((item, index) => {
														return (
															<li key={index}>
																<div className="gradienWrapper">
																	<div className="gradientBg" />
																	<div
																		className="gradientNieve"
																		style={{
																			height: `${item.altura}px`,
																		}}
																	>
																		<p>{item.valor}</p>
																	</div>
																</div>
																<small>{item.horas}</small>
															</li>
														);
													})}
												</ul>
											</div>
										</div>
									)}
								</div>
							</div>
							{hasWebcams && !isMobileResponsive && (
								<WebCams firtWebcam={dynamicCentro.webcams[0]} />
							)}
						</div>
					</PageContent>
				</PageContentWrapper>

				<PageContentWrapper
					bgColor="transparent"
					layout="dark"
					className="resortForecast"
				>
					<PageContent
						width={!isPremium ? "1280px" : "100%"}
						paddingTop="0"
						paddingBottom="0"
					>
						{isMobile768 ? (
							<ForecastMobile
								staticCentro={staticCentro}
								dynamicCentro={dynamicCentro}
							/>
						) : (
							<Forecast
								staticCentro={staticCentro}
								dynamicCentro={dynamicCentro}
							/>
						)}
					</PageContent>
				</PageContentWrapper>

				<PageContentWrapper
					bgColor="transparent"
					layout="dark"
					className="resortExtraBlock"
				>
					<PageContent width="1280px" paddingTop="0" paddingBottom="0">
						{hasWebcams && isMobileResponsive && (
							<WebCams firtWebcam={dynamicCentro.webcams[0]} />
						)}

						{Object.keys(dynamicCentro).length > 0 && (
							<ExtrasBlockGrid
								resortCirclesInfo={resortCirclesInfo}
								staticCentro={staticCentro}
								dynamicCentro={dynamicCentro}
							/>
						)}
					</PageContent>
				</PageContentWrapper>
			</ResortSection>

			<ResortExtraInfoSection>
				<PageContentWrapper layout="light" className="resortInfoExtra">
					<PageContent width="1280px" paddingBottom="0">
						<div className="imgsWrapper">
							<ul>
								<li>
									<h3>
										El clima de {staticCentro.intl.title[intl.locale]}{" "}
										en Fresh Snow
									</h3>
									<p>
										Toda la información de nieve que necesitas para
										programar tu viaje de esquí a{" "}
										{staticCentro.intl.title[intl.locale]} la puedes
										encontrar aquí, nuestro algoritmo te mostrará toda
										la información detallada a 14 días, en 3 alturas y
										en los diferentes momentos del día.
									</p>

									{staticCentro.lugar && (
										<a>
											<Imgs
												title={staticCentro.lugar.title}
												subtitle={staticCentro.lugar.subtitle}
												linkText={`${staticCentro.lugar.centros.length} estaciones`}
												linkTo={`/locations/${staticCentro.lugar.name}`}
												imagen={staticCentro.lugar.imagen_small}
											/>
										</a>
									)}
									<small>
										<Link to="/locations" className="lineLink">
											<u>Descubre todos los lugares</u>
										</Link>
									</small>
								</li>
								<li>
									<h3>
										Instala el widget de Fresh Snow en tu sitio web
									</h3>
									<p>
										¿Quieres tener toda nuestra información en tu
										página web? Ponte en contacto con nosotros y
										podrás contar con este servicio totalmente gratis.
									</p>
									<button className="lineLink" onClick={fakeWidget}>
										<img src="/station/imagenWidget.png" alt="" />
									</button>
									<small>
										<button className="lineLink" onClick={fakeWidget}>
											<u>Quiero el widget</u>
										</button>
									</small>
								</li>
							</ul>
						</div>

						<div className="presentWrapper">
							<Slider
								layout="light"
								type="news"
								title="Actualidad"
								text="Mantente al corriente de las últimas noticias del sector traidas por nuestro partner Lugares de nieve."
							/>
						</div>
					</PageContent>
				</PageContentWrapper>
			</ResortExtraInfoSection>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const { isMobileResponsive, isMobile768 } = state.app.global;
	return {
		dynamicCentro: state.centro.data,
		climaActual:
			state.centro.data &&
			state.centro.data.clima &&
			state.centro.data.clima.actual
				? state.centro.data.clima.actual
				: null,
		bootLoaded: state.app.boot.success,
		lat: state.centros.filters.location.lat,
		long: state.centros.filters.location.long,
		units: state.app.config.units,
		favoritos: state.favoritos.items,
		alertas: state.alertas.items,
		usuario: state.auth.usuario,
		isPremium: state.auth.usuario?.premium,
		loggedIn: state.auth.loggedIn,
		authResolved: state.auth.resolved,
		isMobileResponsive,
		isMobile768,
	};
};

const mapDispatchToProps = {
	centroGet,
	appModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(EstacionPage);
