import React from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";
import { colors } from "../../assets/styles/vars";

export const ExtendedForecastDaySkeletonWrapper = styled.div`
	background-color: ${colors.black16};
`;

const ExtendedForecastDaySkeleton = (props) => (
	<ExtendedForecastDaySkeletonWrapper>
		<ContentLoader
			speed={2}
			// width="100%"
			width={174}
			height={638}
			viewBox="0 0 174 638"
			backgroundColor={colors.black00}
			foregroundColor={colors.black13}
			{...props}
		>
			{/* DAY TEXT */}
			<rect x="8%" y="33" rx="0" ry="0" width="82%" height="18" />
			{/* DAY TEXT */}

			{/* COL 1 */}
			<rect x="14%" y="66" rx="0" ry="0" width="17%" height="46" />

			<rect x="17%" y="209" rx="0" ry="0" width="10%" height="58" />

			<rect x="17%" y="279" rx="0" ry="0" width="10%" height="18" />
			<rect x="17%" y="322" rx="0" ry="0" width="10%" height="18" />

			<rect x="12%" y="355" rx="0" ry="0" width="20%" height="18" />
			<rect x="12%" y="387" rx="0" ry="0" width="20%" height="18" />

			<rect x="17%" y="419" rx="0" ry="0" width="10%" height="18" />
			<rect x="17%" y="452" rx="0" ry="0" width="10%" height="18" />
			<rect x="17%" y="482" rx="0" ry="0" width="10%" height="18" />

			<rect x="17%" y="512" rx="0" ry="0" width="10%" height="18" />
			<rect x="17%" y="554" rx="0" ry="0" width="10%" height="18" />
			<rect x="17%" y="587" rx="0" ry="0" width="10%" height="18" />
			{/* COL 1 */}

			{/* COL 2 */}
			<rect x="41%" y="66" rx="0" ry="0" width="17%" height="46" />

			<rect x="44%" y="189" rx="0" ry="0" width="10%" height="78" />

			<rect x="44%" y="279" rx="0" ry="0" width="10%" height="18" />
			<rect x="44%" y="322" rx="0" ry="0" width="10%" height="18" />

			<rect x="40%" y="355" rx="0" ry="0" width="20%" height="18" />
			<rect x="40%" y="387" rx="0" ry="0" width="20%" height="18" />

			<rect x="44%" y="419" rx="0" ry="0" width="10%" height="18" />
			<rect x="44%" y="452" rx="0" ry="0" width="10%" height="18" />
			<rect x="44%" y="482" rx="0" ry="0" width="10%" height="18" />

			<rect x="44%" y="512" rx="0" ry="0" width="10%" height="18" />
			<rect x="44%" y="554" rx="0" ry="0" width="10%" height="18" />
			<rect x="44%" y="587" rx="0" ry="0" width="10%" height="18" />
			{/* COL 2 */}

			{/* COL 3 */}
			<rect x="69%" y="66" rx="0" ry="0" width="17%" height="46" />

			<rect x="72%" y="209" rx="0" ry="0" width="10%" height="58" />

			<rect x="72%" y="279" rx="0" ry="0" width="10%" height="18" />
			<rect x="72%" y="322" rx="0" ry="0" width="10%" height="18" />

			<rect x="68%" y="355" rx="0" ry="0" width="20%" height="18" />
			<rect x="68%" y="387" rx="0" ry="0" width="20%" height="18" />

			<rect x="72%" y="419" rx="0" ry="0" width="10%" height="18" />
			<rect x="72%" y="452" rx="0" ry="0" width="10%" height="18" />
			<rect x="72%" y="482" rx="0" ry="0" width="10%" height="18" />

			<rect x="72%" y="512" rx="0" ry="0" width="10%" height="18" />
			<rect x="72%" y="554" rx="0" ry="0" width="10%" height="18" />
			<rect x="72%" y="587" rx="0" ry="0" width="10%" height="18" />
			{/* COL 3 */}
		</ContentLoader>
	</ExtendedForecastDaySkeletonWrapper>
);

export default ExtendedForecastDaySkeleton;
