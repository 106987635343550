import React from "react";
import styles from "./extrasblockgrid.module.scss";
import NearestCentros from "../NearestCentros";
import Tarifas from "../Tarifas";
import LastReviews from "../LastReviews";
import StationAd from "../StationAd";
import Map from "../Map";
import InfoCircles from "../Common/InfoCircles";
//import TrackVisibility from "react-on-screen";

const ExtrasBlockGrid = ({
	resortCirclesInfo,
	staticCentro,
	dynamicCentro,
}) => {
	return (
		<div className={styles.extrasBlockGrid}>
			<div
				className={`${styles.extrasBlock} ${styles.mapBlock} ${
					!dynamicCentro.ad ? styles.fullWidth : ""
				}`}
			>
				<div className={styles.innerExtraBlock}>
					<Map
						lat={staticCentro.lat}
						long={staticCentro.long}
						zoom={14}
						id={`mapCentroId${staticCentro.id}`}
					/>
				</div>
			</div>
			<div className={`${styles.extrasBlock} ${styles.nearestCentrosBlock}`}>
				<div className={styles.innerExtraBlock}>
					{/* <TrackVisibility> */}
					<NearestCentros staticCentro={staticCentro} />
					{/* </TrackVisibility> */}
				</div>
			</div>
			<div className={`${styles.extrasBlock} ${styles.infoBlock}`}>
				<InfoCircles layout="dark" items={resortCirclesInfo} />
			</div>

			<div className={`${styles.extrasBlock} ${styles.adsBlock}`}>
				<div className={styles.innerExtraBlock}>
					{dynamicCentro.ad && (
						<StationAd
							ad={{
								...dynamicCentro.ad,
								logo: dynamicCentro.logo,
								logo_mobile: dynamicCentro.logo_mobile,
							}}
						/>
					)}
				</div>
			</div>

			<div className={`${styles.extrasBlock} ${styles.tarifasBlock}`}>
				<div className={styles.innerExtraBlock}>
					<Tarifas tarifas={dynamicCentro.tarifas} />
				</div>
			</div>
			<div className={`${styles.extrasBlock} ${styles.lastReviewsBlock}`}>
				<div className={styles.innerExtraBlock}>
					<LastReviews
						staticCentro={staticCentro}
						dynamicCentro={dynamicCentro}
					/>
				</div>
			</div>
		</div>
	);
};

export default ExtrasBlockGrid;
