import styled from "styled-components";
import { colors } from "../../assets/styles/vars";

export const ExtendedForecastMobileWrapper = styled.div`
	width: 100%;
	overflow: hidden;
	margin-bottom: 45px;

	h3 {
		font-size: 17px;
		color: ${colors.white};
		line-height: 21px;
		letter-spacing: normal;
		background-color: ${colors.black16};
		padding: 13px 25px;
		margin: 0 0 5px !important;
	}
`;

export const ExtendedForecastMobileContent = styled.div`
	padding: 0 24px;
	margin: 0 -24px;
	border-top: solid 0.5px #0b0b0b;

	.daysQtySelector {
		padding: 0 25px;

		ul {
			list-style: none;
			display: flex;
			padding: 20px 0 0;
			margin: 0;
			line-height: 15px;

			li {
				&:not(:last-of-type) {
					border-right: 1px solid ${colors.gray70};
					margin-right: 12px;
					padding-right: 12px;
				}

				a {
					font-size: 15px;
					color: ${colors.gray82};
				}

				&.selected {
					a {
						color: ${colors.white};
						text-decoration: underline;
					}
				}
			}
		}
	}
`;
