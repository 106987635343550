import React, { useEffect, useRef, useState } from "react";
import { ActionButtonWrapper } from "./styled";
import ActionSkeleton from "./ActionSkeleton";
import ReactTooltip from "react-tooltip";
import { appModal } from "../../../redux/app/actions";
import { favoritoAdd, favoritoDelete } from "../../../redux/favoritos/actions";
import { connect } from "react-redux";
import { useIntl } from "gatsby-plugin-intl";
import { renderIcon, renderToolTip } from "./button-utils";
import { CustomButton } from "../../../assets/styles/globalCommon";

const FavButton = (props) => {
	const {
		centroId,
		showToolTip = false,
		isActive = false,
		layout = "outline",
		withText = false,
		loggedIn,
		favoritoCurrentId,
		favoritoAdd,
		favoritoDelete,
		appModal,
	} = props;
	const intl = useIntl();

	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		if (favoritoCurrentId === centroId) {
			setIsLoading(true);
		} else {
			setIsLoading(false);
		}
	}, [favoritoCurrentId, centroId]);

	const tooltipRef = useRef();
	useEffect(() => {
		if (tooltipRef.current) ReactTooltip.show(tooltipRef.current);
	}, []);

	const addFavHandler = () => {
		if (!showToolTip) {
			if (!loggedIn) {
				appModal("popuplogin", { campaign: "alerts" });
			} else {
				if (isActive) {
					favoritoDelete(intl.locale, centroId);
				} else {
					favoritoAdd(intl.locale, centroId);
				}
			}
		}
	};

	return (
		<ActionButtonWrapper withText={withText}>
			<CustomButton
				className={`ghost ${
					!withText ? "onlyIcon" : "withUnderline withIcon"
				}`}
				onClick={() => {
					if (!isLoading) addFavHandler();
				}}
			>
				{withText && (
					<p>{isActive ? "Quitar Favorito" : "Agregar Favorito"}</p>
				)}

				{isActive &&
					showToolTip &&
					renderToolTip(
						"Con solo clicar en la campana podrás habilitar o deshabilitar una alerta 😉.",
						tooltipRef,
					)}
				{isLoading ? (
					<span className="icon">
						<span>
							<ActionSkeleton />
						</span>
					</span>
				) : (
					renderIcon(layout, isActive, "heart")
				)}
			</CustomButton>
		</ActionButtonWrapper>
	);
};

const mapStateToProps = (state) => {
	const { currentId: favoritoCurrentId } = state.favoritos;
	return {
		loggedIn: state.auth.loggedIn,
		favoritoCurrentId,
	};
};

const mapDispatchToProps = {
	appModal,
	favoritoAdd,
	favoritoDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(FavButton);
