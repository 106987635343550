import styled from "styled-components";
import { colors } from "../../assets/styles/vars";
import { CustomButtonWrapper } from "../../assets/styles/globalCommon";

export const TarifasWrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

export const TarifasHeader = styled.div`
	padding: 25px;
	h5 {
		color: ${colors.white};
		margin-bottom: 5px;
	}

	p {
		color: #6b6b6b;
	}
`;

export const TarifasFooter = styled.div`
	padding: 25px;
	//height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 15px;
	> p {
		color: ${colors.gray82};
	}
	${CustomButtonWrapper} {
		width: 100%;
	}
	a {
		width: 100%;
		//justify-content: center;
		p {
			font-weight: 500;
		}
	}
`;

export const TarifasList = styled.div`
	.tarifasScroll {
		overflow-x: hidden;
		overflow-y: scroll;
		height: 188px;
	}
	.tarifasItem {
		position: relative;
		padding: 15px 25px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		&:not(:first-of-type) {
			p {
				&:last-child {
					color: ${colors.main};
				}
			}

			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: ${colors.black0A};
			}
		}

		&:last-child {
			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: ${colors.black0A};
			}
		}

		p {
			color: ${colors.white};
			text-align: center;
			width: 33%;
			font-size: 16px;

			&:nth-child(1) {
				text-align: left;
			}
		}
	}
`;
