import React from "react";
import { ActionButtonWrapper } from "./styled";
import { appModal } from "../../../redux/app/actions";
import { connect } from "react-redux";
import { renderIcon } from "./button-utils";
import {
	CustomButton,
	StyledSliderButton,
} from "../../../assets/styles/globalCommon";

const PublicarParteButton = (props) => {
	const {
		centroId,
		centroTitle,
		usuarioName,
		isActive = false,
		layout = "outline",
		withText = false,
		loggedIn,
	} = props;

	const renderButtonContent = () => {
		return (
			<>
				{withText && <p>{isActive ? "" : "Publicar Parte"}</p>}
				{renderIcon(layout, isActive, "star")}
			</>
		);
	};

	return (
		<ActionButtonWrapper withText={withText}>
			{loggedIn ? (
				<StyledSliderButton
					id="jzgBpEWr"
					hidden={{
						centro: centroId,
						token: localStorage.getItem("token"),
						nombre: usuarioName,
						centro_title: centroTitle,
					}}
					size="75"
					className="ghost withUnderline withIcon"
				>
					{renderButtonContent()}
				</StyledSliderButton>
			) : (
				<CustomButton
					className={`ghost ${
						!withText ? "onlyIcon" : "withUnderline withIcon"
					}`}
					onClick={() => {
						appModal("popuplogin", { campaign: "alerts" });
					}}
				>
					{renderButtonContent()}
				</CustomButton>
			)}
		</ActionButtonWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		loggedIn: state.auth.loggedIn,
	};
};

const mapDispatchToProps = {
	appModal,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PublicarParteButton);
