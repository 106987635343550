import React, { useState, useEffect } from "react";
import montana from "../../assets/gfx/mountain.png";
import { connect } from "react-redux";
import { ReactSVG } from "react-svg";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";
import ForecastAlturasList from "../ForecastAlturasList";
import ExtendedForecastDaysSlider from "../ExtendedForecastDaysSlider";
import UnitsBar from "../UnitsBar";
import {
	ExtendedForecastDesktopWrapper,
	ExtendedForecastContent,
	ExtendedForecastHeader,
} from "./styled";

const ExtendedForecastDesktop = (props) => {
	const {
		staticCentro,
		dynamicCentro,
		altura,
		units,
		appBootSuccess,
		userLoggedin,
		isPremium,
	} = props;
	const [swiperRef, setSwiperRef] = useState(null);

	const sliderChange = (direction) => {
		direction === "next" ? swiperRef?.slideNext() : swiperRef?.slidePrev();
	};

	useEffect(() => {
		const updateSlider = () => {
			if (swiperRef) {
				swiperRef.update();
			}
		};

		updateSlider();
		window.addEventListener("resize", updateSlider);

		return () => {
			window.removeEventListener("resize", updateSlider);
		};
	}, []);

	return (
		<ExtendedForecastDesktopWrapper>
			<ExtendedForecastHeader>
				<div className="daysQty">
					<h3>
						<FormattedMessage id="clima.pronostico_extendido" />
					</h3>
				</div>
				<div className="daysHeights">
					<img src={montana} alt="" title="" />
					<ForecastAlturasList staticCentro={staticCentro} />
				</div>
				<div className="daysArrows">
					<div
						className="arrow arrowLeft"
						onClick={() => sliderChange("prev")}
						onKeyDown={null}
						role="button"
						tabIndex={0}
						aria-label="slider-prev"
					>
						<ReactSVG
							src="/img/icons/icon-arrow-left.svg"
							wrapper="span"
							className="icon"
						/>
					</div>
					<div
						className="arrow arrowRight"
						onClick={() => sliderChange("next")}
						onKeyDown={null}
						role="button"
						tabIndex={0}
						aria-label="slider-next"
					>
						<ReactSVG
							src="/img/icons/icon-arrow-right.svg"
							wrapper="span"
							className="icon"
						/>
					</div>
				</div>
			</ExtendedForecastHeader>

			{/* PRONOSTICO EXTENDIDO CONTENIDO */}
			<ExtendedForecastContent
				isPremium={isPremium}
				userLoggedin={userLoggedin}
			>
				{/* UNIDADES */}
				{appBootSuccess && <UnitsBar units={units} />}
				{/* UNIDADES */}

				{/* PRONOSTICO EXTENDIDO DIAS */}
				{dynamicCentro && (
					<ExtendedForecastDaysSlider
						centroDias={dynamicCentro?.clima?.dias}
						altura={altura}
						units={units}
						isPremium={isPremium}
						sliderRef={swiperRef}
						setSwiperRef={setSwiperRef}
					/>
				)}
				{/* PRONOSTICO EXTENDIDO DIAS */}
			</ExtendedForecastContent>
			{/* PRONOSTICO EXTENDIDO CONTENIDO */}

			{/*{dynamicCentro &&*/}
			{/*	dynamicCentro.clima &&*/}
			{/*	dynamicCentro.clima.dias &&*/}
			{/*	dynamicCentro.clima.dias.map((dia, index) => {*/}
			{/*		// Tooltip estado del cielo*/}

			{/*		const tooltipDia = getCielo(*/}
			{/*			dia.alturas[`altura_${altura}`].franjas.am.clima_codigo,*/}
			{/*		);*/}
			{/*		const tooltipTarde = getCielo(*/}
			{/*			dia.alturas[`altura_${altura}`].franjas.pm.clima_codigo,*/}
			{/*		);*/}
			{/*		const tooltipNoche = getCielo(*/}
			{/*			dia.alturas[`altura_${altura}`].franjas.noche.clima_codigo,*/}
			{/*		);*/}

			{/*		return (*/}
			{/*			<span key={index}>*/}
			{/*				<StyledReactTooltip*/}
			{/*					id={`dia-${index}`}*/}
			{/*					place="bottom"*/}
			{/*					type="light"*/}
			{/*					effect="solid"*/}
			{/*					className="tooltip bottom"*/}
			{/*				>*/}
			{/*					<span>{tooltipDia}</span>*/}
			{/*				</StyledReactTooltip>*/}
			{/*				<StyledReactTooltip*/}
			{/*					id={`tarde-${index}`}*/}
			{/*					place="bottom"*/}
			{/*					type="light"*/}
			{/*					effect="solid"*/}
			{/*					className="tooltip bottom"*/}
			{/*				>*/}
			{/*					<span>{tooltipTarde}</span>*/}
			{/*				</StyledReactTooltip>*/}
			{/*				<StyledReactTooltip*/}
			{/*					id={`noche-${index}`}*/}
			{/*					place="bottom"*/}
			{/*					type="light"*/}
			{/*					effect="solid"*/}
			{/*					className="tooltip bottom"*/}
			{/*				>*/}
			{/*					<span>{tooltipNoche}</span>*/}
			{/*				</StyledReactTooltip>*/}
			{/*				<StyledReactTooltip*/}
			{/*					id={`bar-dia-${index}`}*/}
			{/*					place="bottom"*/}
			{/*					type="light"*/}
			{/*					effect="solid"*/}
			{/*					className="tooltip bottom"*/}
			{/*				>*/}
			{/*					<span>*/}
			{/*						{units === "m"*/}
			{/*							? dia.alturas[`altura_${altura}`].franjas.am*/}
			{/*									.nieve_cm*/}
			{/*							: dia.alturas[`altura_${altura}`].franjas.am*/}
			{/*									.nieve_in}{" "}*/}
			{/*						{units === "m" ? (*/}
			{/*							<FormattedMessage id="clima.cm_nieve_tooltip" />*/}
			{/*						) : (*/}
			{/*							<FormattedMessage id="clima.in_nieve_tooltip" />*/}
			{/*						)}*/}
			{/*					</span>*/}
			{/*				</StyledReactTooltip>*/}
			{/*				<StyledReactTooltip*/}
			{/*					id={`bar-tarde-${index}`}*/}
			{/*					place="bottom"*/}
			{/*					type="light"*/}
			{/*					effect="solid"*/}
			{/*					className="tooltip bottom"*/}
			{/*				>*/}
			{/*					<span>*/}
			{/*						{units === "m"*/}
			{/*							? dia.alturas[`altura_${altura}`].franjas.pm*/}
			{/*									.nieve_cm*/}
			{/*							: dia.alturas[`altura_${altura}`].franjas.pm*/}
			{/*									.nieve_in}{" "}*/}
			{/*						{units === "m" ? (*/}
			{/*							<FormattedMessage id="clima.cm_nieve_tooltip" />*/}
			{/*						) : (*/}
			{/*							<FormattedMessage id="clima.in_nieve_tooltip" />*/}
			{/*						)}*/}
			{/*					</span>*/}
			{/*				</StyledReactTooltip>*/}
			{/*				<StyledReactTooltip*/}
			{/*					id={`bar-noche-${index}`}*/}
			{/*					place="bottom"*/}
			{/*					type="light"*/}
			{/*					effect="solid"*/}
			{/*					className="tooltip bottom"*/}
			{/*				>*/}
			{/*					<span>*/}
			{/*						{units === "m"*/}
			{/*							? dia.alturas[`altura_${altura}`].franjas.noche*/}
			{/*									.nieve_cm*/}
			{/*							: dia.alturas[`altura_${altura}`].franjas.noche*/}
			{/*									.nieve_in}{" "}*/}
			{/*						{units === "m" ? (*/}
			{/*							<FormattedMessage id="clima.cm_nieve_tooltip" />*/}
			{/*						) : (*/}
			{/*							<FormattedMessage id="clima.in_nieve_tooltip" />*/}
			{/*						)}*/}
			{/*					</span>*/}
			{/*				</StyledReactTooltip>*/}
			{/*			</span>*/}
			{/*		);*/}
			{/*	})}*/}
		</ExtendedForecastDesktopWrapper>
	);
};

const mapStateToProps = (state, _ownProps) => {
	const { altura, units } = state.app.config;
	return {
		altura,
		units,
		appBootSuccess: state.app.boot.success,
		userLoggedin: state.auth.loggedIn,
		isPremium: state.auth.usuario?.premium,
	};
};

export default connect(
	mapStateToProps,
	null,
)(injectIntl(ExtendedForecastDesktop));
